import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { IonButton, IonIcon, IonList } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { getSubscriptions } from '../../store/subscription/actions';
import { setSelectedSubscription } from '../../store/orders/actions';
import { forwardTo } from '../../lib/utils';
import { SubscriptionLine } from '../../components/subscription-item';
import coneImage from '../../assets/images/icons/cone.png'
import { addCircleOutline } from 'ionicons/icons';
const handleMembership = () => forwardTo('/membership-terms');

const ChooseSubscription = ({
  __,
  subscriptions,
  selectedSubscription,
  activeSubscription,
  getSubscriptions,
  setSelectedSubscription,
}) => {
  useEffect(() => {
    getSubscriptions();
  }, []);

  if (activeSubscription && subscriptions.find(subscription => subscription.id === activeSubscription.id)) {
    forwardTo('/my-membership');
  }

  let imageUrl = null;

  const items = subscriptions.map((e, idx) => {
    const isChecked = idx === selectedSubscription?.idx;
    if (imageUrl === null) {
      imageUrl = e.image;
    }
    const price = e.monthly_cost_inc_vat / 100;

    return SubscriptionLine(
      __,
      () => setSelectedSubscription({ id: e.id, idx }),
      idx,
      isChecked,
      e.name,
      e.short_description,
      price,
    );
  });
  const discountRate = subscriptions[0]?.discount_rate
  return (
    <Loading transparent>
      <Layout color='transparent' scrollY={false} headerTitle={__('MembershipChooser')} hideSecondToolbar={true}>
        <div className="subscription-backgorund">
          <img src={coneImage}></img>
        </div>

        <div className="absolute-content  subscription-wrapper">
          <div className='subscription-header'>
            <div className='subscription-header-top'>
              <div className='logo-wrapper'></div>
              <p>{__('JOIN THE')}<br /><b>{__('GW BREWERY CLUB')}</b><br /> & <b>{__('GET')} {discountRate}%</b><br />{__('OFF ALL ORDERS')}</p>
            </div>
            <div className='subscription-header-spacer'></div>
            <div className='subscription-header-bottom'>
              <IonIcon slot="icon-only" icon={addCircleOutline} />

              <div>
                <p>Special Offers & Exclusive Invites</p>
              </div>
            </div>
          </div>
          <div className='subscription-list'>
            <IonList>
              {items}

            </IonList>
          </div>
          <IonButton
            disabled={selectedSubscription === null}
            onClick={handleMembership}
            expand="block"
            className={'checkout-btn '}
          >
            {__('Continue')}
          </IonButton>
        </div>
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions,
  selectedSubscription: store.orders.selectedSubscription,
  activeSubscription: store.profile.profile.active_subscription
});

const mapDispatchToProps = {
  setSelectedSubscription,
  getSubscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(ChooseSubscription));
