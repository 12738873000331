import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { Hr, NormalText, SmallText, Spacer, Title } from '../../components/common';
import { IonAlert, IonButton, IonList } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import {
  getSubscriptions,
  unsubscribe,
  reactivateSubscription,
} from '../../store/subscription/actions';
import { sprintf, forwardTo } from '../../lib/utils';
import Basket from '../../lib/basket';
import { SubscriptionLine } from '../../components/subscription-item';
import moment from 'moment';

const MyMembership = ({
  __,
  subscriptions,
  active_subscription,
  getSubscriptions,
  unsubscribe,
  reactivateSubscription,
}) => {
  useEffect(() => {
    getSubscriptions();
  }, []);

  const [showSwitchMembershipAlert, setShowSwitchMembershipAlert] = useState(false);
  const [showCancelMembershipAlert, setShowCancelMembershipAlert] = useState(false);
  const [showReactivatingMembershipAlert, setShowReactivatingMembershipAlert] = useState(false);

  const handleClick = (active_subscription) => () => {
    if (active_subscription.cancellation_pending) {
      setShowReactivatingMembershipAlert(true);
    } else {
      setShowCancelMembershipAlert(true);
    }
  };

  const cancelMembershipAction = () => {
    setShowCancelMembershipAlert(false);
  unsubscribe();
  };

  const reactivateMembershipAction = () => {
    setShowReactivatingMembershipAlert(false);
    reactivateSubscription();
  };

  const items = subscriptions.map((e, idx) => {
    const isChecked = e.id === active_subscription.id;
    const price = e.monthly_cost_inc_vat / 100;

    return SubscriptionLine(__, () => {}, idx, isChecked, e.name, e.short_description, price);
  });

  const days_left = moment(active_subscription.next_renewal_date).diff(moment(), 'days');

  return (
    <Loading transparent>
      <Layout hideSecondToolbar={true} color="transparent"  headerTitle={__('My Membership')}>
        <div className="absolute-wrapper flex-row-wrapper">
          <div className="ion-text-center topbackground rounded-bottom flex-min my-membership-header">
            <Title className="centered">{__('GW BREWERY MEMBERSHIP CLUB')}</Title>
            <Title className='centered days-remaining'>{days_left}</Title>
            <SmallText>{__('Days Remaining')}</SmallText>
            <Spacer size={2}/>
            {active_subscription.cancellation_pending ? (
              <SmallText>
                {__('Plan will end on: ') + active_subscription.next_renewal_date || '??'}
              </SmallText>
            ) : (
              <>
                <SmallText>
                  {__('Plan will renew on: ') + active_subscription.next_renewal_date || '??'}
                </SmallText>
                <br />
                <SmallText>
                  {sprintf(
                    __('You will be charged %s on this date'),
                    Basket.formatPrice(active_subscription.package_cost / 100),
                  )}
                </SmallText>
              </>
            )}
          </div>

          <div className="flex-row-wrapper my-membership-content">
            <div className="scrollable-y ">
              <Title>{__('Modify Membership')}</Title>
              <NormalText>
                {__(
                  'You can change your package at any time and it will start at your next renewal date',
                )}
              </NormalText>
              <IonList className="ion-padding-top subscription-list">{items}</IonList>
            </div>
            <div className="flex-min">
              <IonButton
              color='white'
                disabled={active_subscription === null}
                onClick={handleClick(active_subscription)}
                expand="block"
                className={
                  'checkout-btn ' + (active_subscription.cancellation_pending ? 'greyed' : '')
                }
              >
                {active_subscription.cancellation_pending
                  ? __('Cancellation Pending - Reactivate?')
                  : __('Cancel Membership')}
              </IonButton>
            </div>
          </div>
        </div>

        <IonAlert
          isOpen={showSwitchMembershipAlert}
          header={__('Confirm Change')}
          message={__(
            'Are you sure you wish to change your membership? Your new package will start at the next scheduled renewal date.',
          )}
          buttons={[
            { text: __('Cancel'), handler: () => setShowSwitchMembershipAlert(false) },
            { text: __('OK'), handler: () => setShowSwitchMembershipAlert(false) },
          ]}
        />
        <IonAlert
          isOpen={showCancelMembershipAlert}
          header={__('Are you sure')}
          message={__('By cancelling your membership it will not renew on the scheduled date.')}
          buttons={[
            { text: __('Back'), handler: () => setShowCancelMembershipAlert(false) },
            { text: __('OK'), handler: cancelMembershipAction },
          ]}
        />
        <IonAlert
          isOpen={showReactivatingMembershipAlert}
          header={__('Are you sure')}
          message={__(
            'By reactivating your membership it will renew on the scheduled renewal date.',
          )}
          buttons={[
            { text: __('Back'), handler: () => setShowReactivatingMembershipAlert(false) },
            { text: __('OK'), handler: reactivateMembershipAction },
          ]}
        />
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions,
  active_subscription: store.profile.profile.active_subscription,
});

const mapDispatchToProps = {
  getSubscriptions,
  unsubscribe,
  reactivateSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(MyMembership));
