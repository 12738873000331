import React from 'react';
import { withRouter } from 'react-router';
import { IonButton, IonIcon, IonItem } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { SmallText, Spacer, StrongText } from '../../components/common';
import Basket from '../../lib/basket';
import ValidateButton from '../../components/validateButton';
import { getConfig } from '../../appConfig';
import Modal from '../../components/modal';
import { ApplyPoints } from '../../screens/applyPoints';
import Incrementer from '../../components/incrementer';
import { connect } from 'react-redux';
import OrderContent from '../../components/orderContent';
import {
  forwardTo,
  validateProfileData,
  goBack,
  go,
  isWebConfig,
} from '../../lib/utils';
import { setModal, setProtectedReferrer, restoreAuth } from '../../store/actions';
import redeemIcon from '../../assets/images/reedem-point-icon.svg';
import ContentHeader from '../../components/contentHeader';
import './index.css';
import {
  setOrderTypeMembership,
  setOrderTypeRegular,
  setOrderSubscriptionFlow,
  setSelectedSubscription,
  getSubscriptions,
} from '../../store/actions';
import './index.css';
import { ApplyAllergens } from '../allergens';
const {
  changeItemQuantity,
  itemsCount,
  setMobile,
  getMobile,
  getAllergen,
  getTotal,
  isMinimumOrderTotalSatisfied,
  getPickUpPoint,
  getDeliveryAddress,
  getDeliveryOption,
  getAppliedVocuher
} = Basket;

class OrderSummaryRaw extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      quantityModal: null,
      allergenModalOpen: false,
      applyPointsModalOpen: false,
      mobile: getMobile() || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    setMobile(val);
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else {
      goBack();
    }
  };
  componentDidMount() {
    const { selectedSubscription, subscriptions, profile } = this.props;
    const basketInstance = this.props.basketInstance || Basket;

    if (selectedSubscription?.idx >= 0 && profile.active_subscription.id) {
      const active_subscription = subscriptions[selectedSubscription?.idx];
      if (active_subscription && active_subscription.id && this.props.isCollection) {
        Basket.setDiscount(active_subscription,'AS');
      }
    } else if (profile.active_subscription && profile.active_subscription.id) {
      const index = subscriptions.findIndex((e) => e.id === profile.active_subscription.id);
      this.props.setSelectedSubscription({ id: profile.active_subscription.id, idx: index });
      basketInstance.setSubscription(profile.active_subscription);
    }
  }
  handleSubmit = () => {
    const { cards, auth, history } = this.props;
    const isAuth = auth.loggedIn;
    if (!isMinimumOrderTotalSatisfied()) {
      // display toast with flag 'true'
      isMinimumOrderTotalSatisfied(true);
      return;
    }
    if (parseInt(getTotal()) === 0 && isAuth) {
      Basket.createOrder();
      return;
    }
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.pathname));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
      return;
    }
    else{
      if (this.props.profile.mobile && this.props.profile.first_name) {
        forwardTo('/checkout');
        setMobile(this.props.profile.mobile);
      } else {
        forwardTo('/contact-details');
      }
    }
  };

  handleOrderItemClick = (item, index) => {
    this.setState({ quantityModal: { item, index } });
  };

  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };

  handleApplyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      if (isWebConfig()) {
        this.setState({ applyPointsModalOpen: flag });
      } else {
        forwardTo('/apply-points');
      }
    }
  };
  handleAllergensModal = (flag) => {
    if (isWebConfig()) {

      this.setState({ allergenModalOpen: flag });
    } else {
      forwardTo('/allergens');
    }

  };
  render() {
    const { __, profile, auth, dispatch } = this.props;
    const { quantityModal, allergenModalOpen, applyPointsModalOpen } = this.state;
    const valid = validateProfileData(profile).isValid;
    const allergens = getAllergen() || [];
    const isAuth = auth.loggedIn;
		const appliedVouchers = Basket.getAppliedVocuher()

    const labelTitle =
      Basket.getOrderType() === 'Click & Collect' ? 'Click & Collect Order' : Basket.getOrderType();
    return (
      <>
        <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y checkout">
            {Basket.getItems().length > 0 ? (
              <ContentHeader
                __={__}
                deliveryOption={getDeliveryOption() || {}}
                orderType={labelTitle}
                deliveryAddress={getDeliveryAddress()}
                pickUpPoint={getPickUpPoint()}
              />
            ) : null}
            <Spacer />
            <StrongText className='roboto-slab big-text'>{__('Items Ordered')}</StrongText>
            {itemsCount() > 0 ? (
              <OrderContent
                showAddItems={true}
                handleOrderItemClick={this.handleOrderItemClick.bind(this)}
                handleApplyModal={this.handleApplyModal}
              />
            ) : (
              <IonItem lines="none">
                <div
                  tabIndex="-1"
                  className="sectiontitle"
                  style={{ width: '100vh', textAlign: 'center' }}
                >
                  {__('No items')}
                </div>
              </IonItem>
            )}
            <div className="validate-content">
              <ValidateButton />
              {isAuth && !valid && getConfig().appType.hasEmailValidationEnabled ? (
                <div className="verified-content">
                  <SmallText color="grey">
                    {__('You can earn, but not redeem points until your account is verified')}
                  </SmallText>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex-min">
            {allergens.length > 0 ? (
              <IonButton
                fill="clear"
                expand="block"
                className="link underlined"
                color="tertiary"
                onClick={() => this.handleAllergensModal(true)}
              >
                {__('View allergen information')}
              </IonButton>
            ) : null}
            <IonButton
              className={`redeem-points-btn ${itemsCount() === 0 || !valid ? 'disabled' : ''}`}
              onClick={() => {
                if ((itemsCount() === 0 || !valid) && isAuth) {
                  dispatch(setModal('isVerfiedModalOpen', true));
                } else {
                  forwardTo('/apply-vouchers');
                }
              }}
              expand="block"
              fill="outline"
              color="dark"
            >
              {appliedVouchers && appliedVouchers.length > 0
                ? __('Replace Voucher')
                : __('Apply Vouchers')}
            </IonButton>
            {getConfig().appType.hasUnlockRewardsOnLoyalty ? null : (
              <IonButton
                expand="block"
                fill="outline"
                color="dark"
                className={`redeem-points-btn ${itemsCount() === 0 || !valid || profile.available_balance === 0 ? 'disabled' : ''}`}
                onClick={() => {
                  if (profile.available_balance === 0) {
                    return null;
                  }
                  if ((itemsCount() === 0 || !valid) && isAuth) {
                    dispatch(setModal('isVerfiedModalOpen', true));
                  } else {
                    this.handleApplyModal(true);
                  }
                }}
              >
                <IonIcon
                  slot="start"
                  size="medium"
                  icon={redeemIcon}
                  expand="block"
                  fill="outline"
                  color="dark"
                />
                {__('Redeem Points')}
              </IonButton>
            )}
            <IonButton
              disabled={itemsCount() === 0}
              onClick={this.handleSubmit}
              color="tertiary"
              expand="block"
              className={'checkout-btn uppercase ' + (!isMinimumOrderTotalSatisfied() ? 'greyed' : '')}
            >
              {__('Checkout')}
            </IonButton>
          </div>
        </div>

        <Modal
          cssClass="quantity-modal"
          title={__('Change quantity')}
          action={this.updateItemQuantity}
          actionLabel={__('Change')}
          isOpen={!!quantityModal}
          onDidDismiss={() => this.setState({ quantityModal: null })}
        >
          {quantityModal && quantityModal.item ? (
            <Incrementer
              allowNegative={false}
              quantity={quantityModal.item.quantity}
              onUpdate={this.onIncrementerUpdate}
            />
          ) : null}
        </Modal>
        <Modal
          cssClass="allergens-modal"
          isOpen={allergenModalOpen}
          onDidDismiss={() => this.handleAllergensModal(false)}
        >
          <ApplyAllergens
            handleAllergensModal={this.handleAllergensModal}
            allergenModalOpen={allergenModalOpen}
          />
          <div>
          </div>
        </Modal>

        <Modal
          cssClass="apply-points-modal"
          isOpen={applyPointsModalOpen}
          onDidDismiss={() => this.handleApplyModal(false)}
        >
          <ApplyPoints
            handleApplyModal={this.handleApplyModal}
            applyPointsModalOpen={applyPointsModalOpen}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { basketUpdated, deliveryOption, selectedSubscription } = store.orders;
  const { auth } = store.profile;
  return {
    basketUpdated,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    deliveryOption,
    itemAllergens: store.restaurants.itemAllergens,
    auth,
    isCollection: deliveryOption !== null && deliveryOption.id === 'collection',
    selectedSubscription,
    subscriptions: store.subscription.subscriptions,
  };
};

const mapDispatchToProps = {
  setOrderTypeMembership,
  setOrderTypeRegular,
  setOrderSubscriptionFlow,
  setSelectedSubscription,
  getSubscriptions,
};

export const OrderSummary = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation(OrderSummaryRaw)));
class OrderSummaryWrapped extends React.Component {
  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipBackToThePreviousPage
    ) {
      forwardTo('/order');
    } else {
      goBack();
    }
  };
  render() {
    const { __ } = this.props;
    return (
      <Loading>
        <Layout hideSecondToolbar={true} headerTitle={__('Order Summary')} backHandler={this.backHandler} scrollY={false}>
          <OrderSummary />
        </Layout>
      </Loading>
    );
  }
}

export default withTranslation(OrderSummaryWrapped);
