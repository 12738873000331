import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
} from '@ionic/react';
import Layout from '../../components/layout';
import {
  getIkentooMenu,
  getIkentooMenusForLocation,
  passedTheOrder,
} from '../../store/restaurants/actions';
import { setCommonModal } from '../../store/common/actions';
import { FieldError, Sectiontitle, SmallText, Title } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { isDefined, checkForDeliveryOption, isObject, isArray, getDistance, groupBy, weekdays } from '../../lib/utils';
import moment from '../../lib/moment';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import { setDeliveryOption } from '../../store/actions';
import './index.css';
import NoData from '../../components/noData';
import { getConfig } from '../../appConfig';
import mobiscroll from '@mobiscroll/react';

export const formatDataForTime = (
  store,
  minDate,
  selectedRestaurant,
) => {
  let timesInBetween = [];
  let storeTimes = [];
  const scheduledDeliveryPeriod = (store && store?.active_menu?.scheduled_delivery) ? store?.active_menu?.scheduled_delivery?.slot_duration_mins : 5
  const minDT = minDate.format('HH:mm');
  const date = moment(minDate);
  const dow = 'w' + date.day();
  const period = scheduledDeliveryPeriod;

  if (store) {
    let slot = store?.active_menu?.scheduled_delivery?.slots[date.format('dddd').toLowerCase()]
    if (slot?.start_time) {
      storeTimes.push({
        d: dow,
        start: slot.start_time,
        end: slot.end_time,
        prep_time: slot.prep_time
      })
    }
  }

  function returnTimesInBetween(start, end, prep_time, cnt) {
    let _start = moment().hours(parseInt(start.split(':')[0])).minutes(parseInt(start.split(':')[1])).seconds(0)
    let _end = moment().hours(parseInt(end.split(':')[0])).minutes(parseInt(end.split(':')[1])).seconds(0)
    for (let _time = _start; _time.isSameOrBefore(_end); _time.add(period, 'minutes')) {
      timesInBetween.push({
        text: _time.format('HH:mm'),
        value: _time.format('HH:mm'),
        prep_time
      })
    }

    //if we have no oppning times, just add 'CLOSED' label to the picker
    if (
      timesInBetween &&
      timesInBetween.length > 1 &&
      timesInBetween[0] &&
      timesInBetween[0].text === 'Closed'
    ) {
      timesInBetween.shift();
    }
    //if we have no oppning times, just add 'CLOSED' label to the picker
    if (isDefined(store) && timesInBetween.length === 0) {
      timesInBetween.push({ text: 'Closed', value: null });
    }
    return timesInBetween;
  }

  storeTimes.forEach((storeT, i, arr) => {

    returnTimesInBetween(storeT?.start, storeT?.end, storeT?.prep_time, i);
    let minH = parseInt(minDT.split(':')[0]);
    let minM = parseInt(minDT.split(':')[1]);
    let endTimeH = parseInt(storeT?.end.split(':')[0]);
    let endTimeM = parseInt(storeT?.end.split(':')[1]);
    let minTime = date.hours(minH).minutes(minM);
    let timeEnd = date.hours(endTimeH).minutes(endTimeM);
    if (i < arr.length - 1 && arr.length > 0 && moment(minTime).isSameOrBefore(timeEnd)) {
      timesInBetween.push({ text: 'Closed', value: null });
    }
  });

  //remove 'CLOSED' label if that is first time
  if (
    timesInBetween &&
    timesInBetween.length > 1 &&
    timesInBetween[0] &&
    timesInBetween[0].text === 'Closed'
  ) {
    timesInBetween.shift();
  }
  if (timesInBetween.length === 0 && selectedRestaurant) {
    timesInBetween.push({ text: 'Closed', value: null });
  }
  return timesInBetween;
};

class ScheduledDeliveryTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickTime: null,
      selectedDate: '',
      error: '',
      isLocationAllowed: false,
      orderPassed: false,

    };
    this.dateSlotRef = React.createRef();
    this.timeSlotRef = React.createRef();
    this.timePickerOptions = {};
  }

  componentDidMount() {
    const { deliveryOption } = this.props;
    let dataForDeliveryOption = null;
    if (!deliveryOption) {
      const order_type = Basket.getOrderType();
      const deliveryConfig = getConfig().delivery;
      Object.keys(deliveryConfig).map((key) => {
        if (deliveryConfig[key].id === order_type.toLowerCase()) {
          dataForDeliveryOption = deliveryConfig[key];
        }
      });
      this.props.dispatch(setDeliveryOption(dataForDeliveryOption));
    }
    const scheduledDelivery = deliveryOption
      ? deliveryOption.id === 'scheduled-delivery'
        ? true
        : false
      : false;
    if (scheduledDelivery || dataForDeliveryOption) {
      // this.pickerRefCharter.current.instance.hide();
    } else {
      this.props.dispatch(
        getIkentooMenusForLocation(
          Basket.getRestaurant() ? Basket.getRestaurant().business_location_id : null,
          {},
          true,
        ),
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deliveryOption !== this.props.deliveryOption) {
      checkForDeliveryOption(
        this.props.deliveryOption ? this.props.deliveryOption : Basket.getDeliveryOption(),
        '/delivery-scheduled',
      );
    }
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
      }
    }
  }

  continueOnMenu = (ikentooMenu) => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu } = this.state;
    const selectedRestaurant = Basket.getRestaurant()

    if (selectedRestaurant && (ikentooMenu || selectedIkentooMenu)) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );

      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu || ikentooMenu, businessLocationId));
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };

  chooseMenusForLocation = () => {
    const { restaurants, dispatch } = this.props;
    const { pickTime } = this.state;
    const selectedRestaurant = Basket.getRestaurant()

    if (selectedRestaurant && pickTime) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      let cutoffTimeRes = moment().unix();
      Basket.setCutoffTime(cutoffTimeRes);
      dispatch(
        getIkentooMenusForLocation(businessLocationId, {
          pickTime,
          active_menu: choosenRestaurant?.active_menu?.scheduled_delivery,
        }),
      );
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
      this.setState({ orderPassed: true });
    } else if (!selectedRestaurant) {
      this.setState({ error: 'Please select location' });
    } else {
      this.setState({ error: 'Please select pickup time' });
    }
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  parseSelectedTime(selected) {
    let selectedTimeSlot = selected.match(/\d{1,2}:\d{2} (AM|PM)/)?.[0] || 'CLOSED';
    if (selected === 'init') {
      selectedTimeSlot = this.timePickerOptions[this.state.selectedDate] ? this.timePickerOptions[this.state.selectedDate][0]?.time : selectedTimeSlot
    }
    const selectedTime = this.timePickerOptions[this.state.selectedDate]?.find(
      (option) => option.time === selectedTimeSlot,
    );
    if (selectedTime) {
      this.setState({ pickTime: selectedTime?.snoozed ? null : selectedTime.slot }, () => {
        Basket.setCollectionTime(selectedTime.formattedDT);
        Basket.setOrderType(this.props.deliveryOption.id);
      });
    }
  }

  parseSelectedDate(selected) {
    this.setState({ ...this.state, selectedDate: selected === 'init' ? Object.keys(this.timePickerOptions)[0] : selected }, () => {
      this.refreshTimeSlot()
    })
  }

  formatTimesForTree = (times) => {
    const groupByMonth = groupBy(['date']);
    const grouped = groupByMonth(times);
    return grouped;
  };

  formatPickupTimes = (store) => {
    const formatSelectOptionDays = [];
    const daysAhead = store?.active_menu?.scheduled_delivery?.days_ahead || 7;
    let picker = [];
    Array(daysAhead)
      .fill('')
      .forEach((day, i) => {
        let formatDay = moment().add(i, 'days');

        formatDay = moment()
          .add(i, 'days')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        let formatDayName = formatDay.format('dddd');
        formatSelectOptionDays.push({ formatDayName, formattedDT: formatDay });
      });

    formatSelectOptionDays.map((option, i) => {
      const daySlot =
        formatDataForTime(
          store,
          option.formattedDT,
          Basket.getRestaurant(),
        ) || [];

      daySlot.forEach((slot) => {
        let h = parseInt(slot.text.split(':')[0]);
        let m = parseInt(slot.text.split(':')[1]);
        let formattedDT = moment(option.formattedDT.hours(h).minutes(m));
        let prepTime = moment().add(slot.prep_time, 'hours')
          .add(m, 'minutes');
        if (prepTime.isBefore(formattedDT)) {
          picker.push({
            formattedDT: formattedDT,
            // value: formattedDT.toDate(),
            date: formattedDT.format('ddd, DD MMM'),
            time: formattedDT.format('h:mm A'),
            slot: slot.text
          })
        }
      });
    });
    let unavailable_dates = null
    if (store?.active_menu?.scheduled_delivery) {
      unavailable_dates = store?.active_menu?.scheduled_delivery?.unavailable_dates.map(slot => moment(slot, 'DD/MM/YYYY').format('ddd, DD MMM'))
    }

    const formattedTreeDataSource = this.formatTimesForTree(picker);

    Object.keys(formattedTreeDataSource).map((day, index) => {
      if (formattedTreeDataSource[day][0].slot === 'Closed') {
        delete formattedTreeDataSource[day];
      } else {
        if (unavailable_dates && unavailable_dates.length > 0 && unavailable_dates.indexOf(day) > -1) {
          formattedTreeDataSource[day] = [{ time: 'CLOSED' }];
        }
        let slot = store?.active_menu?.scheduled_delivery?.slots[moment(day, 'ddd, DD MMM').format('dddd').toLowerCase()]
        if (moment().format('ddd, DD MMM') === day && slot?.start_time && store?.active_menu?.scheduled_delivery) {
          let cutoff_time = moment().hours(parseInt(slot.start_time.split(':')[0])).minutes(parseInt(slot.start_time.split(':')[1])).seconds(0).add(-slot.prep_time, 'hours')
          if (moment().isAfter(cutoff_time)) {
            delete formattedTreeDataSource[day];
          }
        }
        if (formattedTreeDataSource[day]) {
          formattedTreeDataSource[day] = formattedTreeDataSource[day].map(dt => {
            const slotEndTime = Number(slot?.end_time.replace(':', ''))
            const slotPlusSlotDuration = moment(dt.formattedDT).add(store?.active_menu?.scheduled_delivery?.slot_duration_mins, 'minutes')

            const slotPlusSlotDurationFormated = Number(moment(slotPlusSlotDuration).format('HH:mm').replace(':', ''))
            if (slotEndTime >= slotPlusSlotDurationFormated && slotPlusSlotDurationFormated !== 0) {
              return dt
            }
            else {
              return { time: 'CLOSED' }
            }
          })

          formattedTreeDataSource[day].filter(Boolean)

        }
      }
    });

    const snoozedTimes = store?.snoozed_times['scheduled-delivery'] || []
    snoozedTimes.map(snoozeTime => {
      let _snoozeTime = moment.utc(snoozeTime, 'ddd, DD MMM YYYY-HH:mm').local()
      let temp_data = formattedTreeDataSource[_snoozeTime.format('ddd, DD MMM')]
      let pos = temp_data?.findIndex(item => item.slot === _snoozeTime.format('HH:mm'))
      if (pos > -1) {
        temp_data[pos] = { ...temp_data[pos], snoozed: true }
      }
    })

    this.timePickerOptions = formattedTreeDataSource;
    // this.refreshTimeSlot()
  };

  refreshTimeSlot = () => {
    if (this.timeSlotRef.current) {
      this.timeSlotRef.current.instance.init();
    }
  };

  refreshDateSlot = () => {
    if (this.dateSlotRef.current) {
      this.dateSlotRef.current.instance.init();
    }
  };
  printSlotTimes = (data, store) => {
    if (data) {
      if (data[0].time == 'CLOSED') {
        return <li data-val={data[0].time}>
          <span className={`item-date-picker`}>
            {
              'UNAVAILABLE'
            }
          </span>
        </li>
      }
      else {
        const result = data.filter(dt => dt.time !== 'CLOSED')
        return result.map((el, i) => {
          return <li key={i} data-val={el.time}>
            <span className={`item-date-picker`}>
              {
                el.formattedDT.format('h:mm A') + '-' + moment(el.formattedDT).add(store?.active_menu?.scheduled_delivery?.slot_duration_mins, 'minutes').format('h:mm A')
              }
            </span>
          </li>
        })
      }
    }

  }
  render() {
    const {
      __,
      ikentooMenusForLocation,
      isChooseMenuModalOpen,
      deliveryOption,
    } = this.props;
    const {
      error,
      pickTime,
      selectedIkentooMenu,
    } = this.state;
    const store = Basket.getRestaurant();;
    this.formatPickupTimes(store);

    const menus = ikentooMenusForLocation.filter(menu => menu.ikentooMenuId === store?.active_menu?.scheduled_delivery?.menu_id)

    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    /**/
    return (
      <Layout
        scrollY={false}
        hideSecondToolbar={true}
        headerTitle={__(deliveryOption ? deliveryOption.label : '')}
        noPadding
        className="click-collect-page"
        color="transparent"
      >
        <div className="click-collect-layout">
          <div className="click-collect-wrapper">
            <div className="click-collect-dialog-header">
              <Title>{__('Scheduled Delivery Order')}</Title>
              <SmallText className="heading">
                {__('Select a time to collect your order')}
              </SmallText>
            </div>
            <div className="click-collect-locations">
              <mobiscroll.Treelist
                display="inline"
                layout="liquid"
                showInput={false}
                circular={false}
                ref={this.dateSlotRef}
                focusOnClose={false}
                themeVariant="light"
                width={(180, 160)}
                placeholder={__('Select Collection Time')}
                onChange={(e) => this.parseSelectedDate(e.valueText)}
                onInit={() => this.parseSelectedDate('init')}
                scrollLock
              >
                {Object.keys(this.timePickerOptions).map((date, i) => (
                  <li key={i} data-val={date}>
                    <span className={`item-date-picker ${this.timePickerOptions[date][0].time === 'CLOSED' && 'striked'}`}>
                      {date}
                    </span>
                  </li>
                ))}
              </mobiscroll.Treelist>
              <mobiscroll.Treelist
                display="inline"
                layout="liquid"
                showInput={false}
                circular={false}
                ref={this.timeSlotRef}
                focusOnClose={false}
                themeVariant="light"
                width={(180, 160)}
                placeholder={__('Select Collection Time')}
                onChange={(e) => this.parseSelectedTime(e.valueText)}
                onInit={() => this.parseSelectedTime('init')}
                scrollLock
              >
                {this.printSlotTimes(this.timePickerOptions[this.state.selectedDate], store)}

              </mobiscroll.Treelist>
            </div>
          </div>
          <div className="click-collect-button">
            <IonButton
              disabled={!pickTime}
              expand="block"
              color="secondary"
              onClick={() => this.chooseMenusForLocation()}
            >
              {__('Continue')}
            </IonButton>
          </div>
        </div>
        <div
          className="click-collect-pickers-backdrop"
          style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
          onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
        ></div>
        <div className={`click-collect-dialog ${animationMenuClass}`}>
          <Loading transparent> {null} </Loading>
          <div className="click-collect-dialog-layout sc-ion-modal-md">
            <div className="click-collect-dialog-header">
              <h3>{__('Choose menu')}</h3>
            </div>
            <div
              className="click-collect-dialog-closer"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
            >
              <ion-icon
                name="close"
                role="img"
                className="md hydrated"
                aria-label="close"
              ></ion-icon>
            </div>
            <div className="click-collect-dialog-content">
              <IonList lines="none">
                <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                  {menus.length === 0 ? (
                    <NoData />
                  ) : (
                    menus.map((menu) => {
                      const { ikentooMenuId, menuName } = menu;
                      return (
                        <IonItem key={ikentooMenuId} lines="full">
                          <div tabIndex="-1"></div>
                          <IonLabel className="ion-text-wrap">
                            <Sectiontitle>{menuName}</Sectiontitle>
                          </IonLabel>
                          <IonRadio color="secondary" slot="start" value={ikentooMenuId} />
                        </IonItem>
                      );
                    })
                  )}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="click-collect-dialog-action">
              {error ? (
                <IonItem>
                  <div tabIndex="-1"></div>
                  <FieldError className="field-error" value={__(error)} />
                </IonItem>
              ) : null}
              <IonButton
                disabled={pickTime && menus.length > 0 ? false : true}
                expand="block"
                color="secondary"
                onClick={() => this.continueOnMenu()}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isChooseMenuModalOpen, orderProductionMins } = state.common;
  const { restaurants, ikentooMenu, isShowTimePicker, ikentooMenusForLocation } = state.restaurants;
  const { deliveryOption } = state.orders;
  return {
    auth,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    ikentooMenusForLocation: ikentooMenusForLocation || [],
    profile: state.profile.profile,
    isShowTimePicker: isShowTimePicker,
    deliveryOption,
    orderProductionMins: orderProductionMins,
  };
};

export default connect(stateToProps)(withTranslation(ScheduledDeliveryTime));
