import React from 'react';
import { IonButton, IonInput, IonToggle, IonItem, IonLabel, IonList, IonAlert } from '@ionic/react';
import { connect } from 'react-redux';
// import { getConfig } from '../../appConfig'
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { registerRequest, setModal, setSysLocale, setRegisterForm, updateProfile } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import { beforeShowTimePicker, beforeCloseTimePicker } from '../../store/actions';
import {
  FieldError,
  Title,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
} from '../../components/common';
import { validateForm } from '../../lib/utils';
import Loading from '../../components/spinner';
import { Plugins } from '@capacitor/core';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';

const { Device } = Plugins;
const { DatePicker } = Mobiscroll;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      showAdditionalDetails: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfig = {
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      first_name: { type: 'text', required: false },
      last_name: { type: 'text', required: false },
      mobile: { type: 'tel', required: false },
      birthday: { type: 'text', required: false },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
    	let formErrors = { ...this.state.formErrors };
    	formErrors.accepted_terms_and_conditions = undefined;
    	this.setState({ formErrors });
    }
  }

  validateForm() {
    const { registerFormData } = this.props;
    let formErrors = validateForm(this.formConfig, registerFormData);
    this.setState({ formErrors });
    return Object.keys(formErrors).length === 0;
  }

  handleRegister() {
    const { protectedReferrer } = this.props;
    this.props.dispatch(registerRequest({ referrer: protectedReferrer }));
  }
  componentDidMount() {
    const { loggedIn } = this.props.auth;
    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute();
      forwardTo(defaultRoute.path);
    }
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');

  render() {
    const { __, isRegisterModalOpen, registerFormData } = this.props;
    const email = registerFormData.email;
    const password = registerFormData.password;
    const first_name = registerFormData.first_name;
    const last_name = registerFormData.last_name;
    const mobile = registerFormData.mobile;
    const birthday = registerFormData.birthday;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const is_subscribed = registerFormData.is_subscribed;
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const { loggedIn } = this.props.auth;

    return (
      <Loading transparent>
        <Layout
          hideSecondToolbar={true}
          hideBack={true}
          headerTitle={__('Register')}
          color="transparent"
          contentClassName="register-background"
        >
          {!loggedIn ? (
            <div className="absolute-content flex-row-wrapper">
              <Title className="uppercase">{__('Welcome, let’s register')}</Title>
              <Spacer size={2} />
              <IonList>
                <IonItem>
                  <IonLabel color="primary" position="floating">
                    {__('Email address')}
                  </IonLabel>
                  <IonInput
                    placeholder={__('Enter your email address')}
                    onIonChange={(e) => this.handleInput('email', e.target.value)}
                    required={true}
                    type="email"
                    pattern="email"
                    inputmode="email"
                    value={email}
                  ></IonInput>
                </IonItem>
                <FieldError className="field-error" value={__(this.state.formErrors.email)} />
                <IonItem>
                  <IonLabel color="primary" position="floating">
                    {__('Password')}
                  </IonLabel>
                  <PasswordInput
                    placeholder={__('Enter your password')}
                    onIonChange={(e) => this.handleInput('password', e.target.value)}
                    value={password}
                  />
                </IonItem>
                <FieldError className="field-error" value={__(this.state.formErrors.password)} />
              </IonList>
              <Spacer />
              <div className="box-holder box-holder--register">
							<IonList>
								<IonItem lines='none'>
									<div tabIndex="-1"></div>
									<div className="toggle">
										<IonLabel>
											<StrongText className='roboto-slab'>{__('Accept T&Cs')}</StrongText>
											<IonLabel className="ion-text-wrap">
												<SmallText color="primary">{__('By signing up you agree to') + ' '} <span className='underlined' onClick={() => forwardTo('/terms')}>{__('our terms and conditions')}</span> {__('and')} <span className='underlined' onClick={() => forwardTo('/privacy')}>{__('privacy policy')}</span></SmallText>
											</IonLabel>
										</IonLabel>
										<FieldError className="field-error" value={__(this.state.formErrors.accepted_terms_and_conditions)} />
									</div>
									<IonToggle color="secondary" slot="end" checked={accepted_terms_and_conditions} onIonChange={e => this.handleInput('accepted_terms_and_conditions', e.detail.checked)} />
								</IonItem>
								<IonItem lines="none">
									<div tabIndex="-1"></div>
									<IonLabel>
										<StrongText className='roboto-slab'>{__('Email Opt in')}</StrongText >
										<IonLabel className="ion-text-wrap">
											<SmallText color="primary">{__('I\'d like to receive email updates that contain news, offers and promotions')}</SmallText>
										</IonLabel>
									</IonLabel>
									<IonToggle color="secondary" slot="end" checked={is_subscribed} onIonChange={e => this.handleInput('is_subscribed', e.detail.checked)} />
								</IonItem>
							</IonList>
						</div>
						<Spacer size={2} />
              <div className="flex-min">
                <IonButton expand="block" color="secondary" onClick={() => {
                  if (this.validateForm()) this.handleRegister()
                }}>
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          ) : (
            <div className="absolute-content flex-row-wrapper">
              <Title className="uppercase">{__('Personal Details')}</Title>
              <Spacer size={2} />
              <IonList>
                <IonItem>
                  <IonLabel color="primary" position="floating">
                    {__('First Name')}
                  </IonLabel>
                  <IonInput
                    placeholder={__('Enter your first name')}
                    onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={first_name}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel color="primary" position="floating">
                    {__('Last Name')}
                  </IonLabel>
                  <IonInput
                    placeholder={__('Enter your last name')}
                    onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={last_name}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel color="primary" position="floating">
                    {__('Mobile Number')}
                  </IonLabel>
                  <IonInput
                    placeholder={__('Enter your mobile number')}
                    onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                    required={true}
                    type="tel"
                    pattern="tel"
                    inputmode="tel"
                    value={mobile}
                  ></IonInput>
                </IonItem>
                <div className="date-picker-wrapper">
                  <IonLabel color="primary">
                    {__('Date Birth')}
                  </IonLabel>
                  <DatePicker
                    className="data-picker-input"
                    display="bottom"
                    max={yesterday}
                    setText={__('Done')}
                    cancelText={__('Cancel')}
                    value={birthday}
                    onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                    dateFormat="dd/mm/yy"
                    onBeforeShow={() => {
                      this.props.dispatch(beforeShowTimePicker());
                    }}
                    onClose={() => {
                      this.props.dispatch(beforeCloseTimePicker());
                    }}
                  />
                </div>
              </IonList>
              <div className="flex-min">
                <IonButton expand="block" color="secondary" onClick={() => {
                  const profile = {
                    first_name: first_name,
                    last_name: last_name,
                    mobile: mobile,
                    birthday: birthday,
                    is_subscribed,
                  };
                  this.props.dispatch(updateProfile(profile));
                  forwardTo(getDefaultRoute().path);
                }}>
                  {__('Continue')}
                </IonButton>
                <IonButton
                  expand="block"
                  fill="clear"
                  color="tertiary"
                  className="link underlined"
                  onClick={() => forwardTo(getDefaultRoute().path)}
                >
                  {__('Skip & update later')}
                </IonButton>
              </div>
            </div>
          )}
        </Layout>
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData, protectedReferrer } = state.profile;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    protectedReferrer,
  };
};

export default connect(stateToProps)(withTranslation(Register));
