import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonCheckbox,
} from '@ionic/react';
import Layout from '../../components/layout';
import {
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  passedTheOrder,
} from '../../store/restaurants/actions';
import { setCommonModal } from '../../store/common/actions';
import { FieldError, Sectiontitle, SmallText, Title } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { isDefined, checkForDeliveryOption, isObject, isArray, getDistance, groupBy, weekdays } from '../../lib/utils';
import moment from '../../lib/moment';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import Mobiscroll from '../../components/mobiscroll';
import { setDeliveryOption } from '../../store/actions';
import './index.css';
import NoData from '../../components/noData';
import { getConfig } from '../../appConfig';
import mobiscroll from '@mobiscroll/react';

const { SelectOption } = Mobiscroll;

const createMomentFromTime = (time = '') => {
  const parsedTime = time.split(':');
  if (parsedTime.length !== 2 || time === '') {
    return null;
  }
  let hour = parseInt(time.split(':')[0]);
  let minutes = parseInt(time.split(':')[1]);
  return moment().hours(hour).minutes(minutes);
};

const toWhichSegmentTimeBelongs = (time, segments = []) => {
  let timeIsInSegment = -1;
  segments.forEach((segment, index) => {
    const { start, end } = segment;
    const targetTime = createMomentFromTime(time);
    const segmentStart = createMomentFromTime(start);
    const segmentEnd = createMomentFromTime(end);
    if (targetTime.isSameOrAfter(segmentStart) && targetTime.isSameOrBefore(segmentEnd)) {
      timeIsInSegment = index;
    }
  });
  return timeIsInSegment;
};

const isTimeInSegment = (time, segments = []) => {
  return toWhichSegmentTimeBelongs(time, segments) !== -1;
};

const addSegment = (start, end, segments = [], date) => {
  let updatedSegments = [...segments];
  const dayNumber = 'w' + date.day();
  const newSegment = { d: dayNumber, start: start, end: end };

  // check previously added segment. maybe some of them are handled with new added segment
  const oldSegments = [...segments];
  oldSegments.forEach((oldSegment, index) => {
    if (
      isTimeInSegment(oldSegment.start, [newSegment]) &&
      isTimeInSegment(oldSegment.end, [newSegment])
    ) {
      updatedSegments = removeSegment(index, updatedSegments);
    }
  });
  return [...updatedSegments, newSegment];
};

const updateSegment = (segmentIndex, propName, value, segments = []) => {
  let updatedSegments = [...segments];
  if (updatedSegments && updatedSegments[segmentIndex]) {
    updatedSegments[segmentIndex][propName] = value;
  }
  return updatedSegments;
};

const removeSegment = (segmentIndexForRemove, segments = []) =>
  [...segments]
    .map((segment, index) => (index === segmentIndexForRemove ? null : segment))
    .filter((segment) => isDefined(segment));

const parseTimesJson = (json = [], date) => {
  let parsed_json = [];
  const dayNumber = 'w' + date.day();

  // use old logic for 'json_time_selector' json (without: menuId and availabity)
  if (json.length > 0 && !json[0].menuId) {
    return json;
  }

  json.forEach((menu) => {
    (menu.availability || [])
      .filter((i) => i.d === dayNumber)
      .forEach((time) => {
        const { start, end } = time;
        if (parsed_json.length === 0) {
          // add first available time
          parsed_json = addSegment(start, end, parsed_json, date);
        } else {
          if (!isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
            // case 1: start and end dont belong to any other segment
            parsed_json = addSegment(start, end, parsed_json, date);
          } else if (isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
            // case 2: start belong to some segment and end dont bolong to any segment
            const segmentIndex = toWhichSegmentTimeBelongs(start, parsed_json);
            parsed_json = updateSegment(segmentIndex, 'end', end, parsed_json);
          } else if (!isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
            // case 3: end belong to some segment and start dont bolong to any segment
            const segmentIndex = toWhichSegmentTimeBelongs(end, parsed_json);
            parsed_json = updateSegment(segmentIndex, 'start', start, parsed_json);
          } else if (isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
            // case 4: and start and end belongs to some segment
            const startSegmentIndex = toWhichSegmentTimeBelongs(start, parsed_json);
            const endSegmentIndex = toWhichSegmentTimeBelongs(end, parsed_json);
            if (parsed_json && parsed_json[startSegmentIndex] && parsed_json[endSegmentIndex]) {
              const newStartTime = parsed_json[startSegmentIndex].start;
              const newEndTime = parsed_json[endSegmentIndex].end;

              if (startSegmentIndex !== endSegmentIndex) {
                parsed_json = addSegment(newStartTime, newEndTime, parsed_json, date);
                parsed_json = removeSegment(startSegmentIndex, parsed_json);
                parsed_json = removeSegment(endSegmentIndex, parsed_json);
              }
            }
          }
        }
      });
  });

  // sort times by 'start' time
  return parsed_json.sort((a, b) => {
    const aStart = createMomentFromTime(a.start);
    const bStart = createMomentFromTime(b.start);
    return bStart.isSameOrBefore(aStart) ? 1 : -1;
  });
};

/*
menusForLocation: [{menuName: "Winter Menu 2014", ikentooMenuId: 37082747671397}, ...]
pickTime: 12:45
json_time_selector: [{
  "menuId": 37082747671609,
  "availability": [
    { "d": "w1", "start": "07:15", "end": "15:45" },
    { "d": "w2", "start": "07:15", "end": "15:45" },
    { "d": "w3", "start": "07:15", "end": "15:45" },
    { "d": "w4", "start": "07:15", "end": "15:45" },
    { "d": "w5", "start": "07:15", "end": "15:45" }
  ]
}, ... ]
*/
export const getMenusForSelectedTime = (
  menusForLocation = [],
  pickTime,
  json_time_selector = [],
) => {
  if (isObject(menusForLocation) && !isArray(menusForLocation)) {
    menusForLocation = [menusForLocation];
  }

  if (pickTime === 'asap') {
    return menusForLocation;
  } else {
    return menusForLocation.filter((menu) => {
      const ikentooMenuId = menu.ikentooMenuId;
      if (json_time_selector.length > 0 && json_time_selector[0].menuId && pickTime) {
        const target_menu = json_time_selector.find(
          (i) => i.menuId.toString() === ikentooMenuId.toString()
        );

        if (
          target_menu &&
          isTimeInSegment(
            pickTime,
            target_menu.availability.filter((i) => i?.d === 'w' + moment().day()),
          )
        ) {
          return true;
        }
      } else {
        // handle old json_time_selector (without menuId and availability)
        return false;
      }

      return false;
    });
  }
};

export const formatDataForTime = (
  store,
  minDate,
  selectedRestaurant,
  isCharterDelivery,
  isTableOrder,
  isScheduledClickCollect
) => {
  let timesInBetween = [];
  let storeTimes = [];
  const charterDeliveryPeriod =
    store && store.charter_delivery_order_slot_interval_mins
      ? store.charter_delivery_order_slot_interval_mins
      : 30;
  const clickAndCollectPeriod =
    store && store.order_slot_interval_mins ? store.order_slot_interval_mins : 5;
  const scheduledClickCollectPeriod = (store && store?.active_menu?.scheduled_collection) ? store?.active_menu?.scheduled_collection?.slot_duration_mins : 5
  const minDT = minDate.format('HH:mm');
  const date = moment(minDate);
  const dow = 'w' + date.day();
  const period = isCharterDelivery ? charterDeliveryPeriod : !isScheduledClickCollect ? clickAndCollectPeriod : scheduledClickCollectPeriod;

  const collection_minutes = [];
  let minutes = -period;

  while (minutes < 60) {
    minutes += period;
    if (minutes < 60) {
      collection_minutes.push(minutes);
    }
  }

  if (store) {
    if (isScheduledClickCollect) {
      let slot = store?.active_menu?.scheduled_collection?.slots[date.format('dddd').toLowerCase()]
      if (slot?.start_time) {
        storeTimes.push({
          d: dow,
          start: slot.start_time,
          end: slot.end_time
        })
      }
    } else {
      parseTimesJson(
        isCharterDelivery
          ? store.charter_delivery_times_json
          : isTableOrder
            ? store.table_json_time_selector
            : store.json_time_selector,
        date,
      ).forEach((time) => {
        if (time.d === dow) {
          storeTimes.push(time);
        }
      });
    }
  }

  function makePickerText(times, j, i) {
    let collectionMinutes = parseInt(times[j]) < 10 ? '0' + times[j] : times[j];
    return {
      text: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes,
      value: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes,
    };
  }

  function returnTimesInBetween(start, end, cnt) {
    let startH = parseInt(start?.split(':')[0]);
    let startM = parseInt(start?.split(':')[1]);
    let endH = parseInt(end?.split(':')[0]);
    let endM = parseInt(end?.split(':')[1]);
    let minTimeStart = parseInt(minDT.split(':')[0]);
    let minTimeEnd = parseInt(minDT.split(':')[1]);
    let c = collection_minutes.filter((cm) => cm >= startM);
    let cm = collection_minutes.filter((cm) => cm <= endM);
    let startHH = startH;
    if (startHH <= minTimeStart) {
      startHH = minTimeStart;
    }

    if (isScheduledClickCollect) {
      let _start = moment().hours(parseInt(start.split(':')[0])).minutes(parseInt(start.split(':')[1])).seconds(0)
      let _end = moment().hours(parseInt(end.split(':')[0])).minutes(parseInt(end.split(':')[1])).seconds(0)
      for (let _time = _start; _time.isSameOrBefore(_end); _time.add(period, 'minutes')) {
        timesInBetween.push({
          text: _time.format('HH:mm'),
          value: _time.format('HH:mm')
        })
      }
    } else {
      for (let i = startHH; i <= endH; i++) {
        if (startH === i) {
          for (let j = 0; j < c.length; j++) {
            if (c[j] >= minTimeEnd && cnt === 0 && startH <= minTimeStart) {
              timesInBetween.push(makePickerText(c, j, i));
            } else if (cnt !== 0) {
              timesInBetween.push(makePickerText(c, j, i));
            } else if (startH > minTimeStart) {
              timesInBetween.push(makePickerText(c, j, i));
            }
          }
        } else if (endH === i) {
          if (minTimeStart === i) {
            for (let j = 0; j < cm.length; j++) {
              if (cm[j] >= minTimeEnd) {
                timesInBetween.push(makePickerText(cm, j, i));
              }
            }
          } else {
            for (let j = 0; j < cm.length; j++) {
              timesInBetween.push(makePickerText(cm, j, i));
            }
          }
        } else {
          if (i === minTimeStart) {
            let collection_m = collection_minutes.filter((cm) => cm >= minTimeEnd);
            for (let j = 0; j < collection_m.length; j++) {
              timesInBetween.push(makePickerText(collection_m, j, i));
            }
          } else {
            for (let j = 0; j < collection_minutes.length; j++) {
              timesInBetween.push(makePickerText(collection_minutes, j, i));
            }
          }
        }
      }
    }

    //if we have no oppning times, just add 'CLOSED' label to the picker
    if (
      timesInBetween &&
      timesInBetween.length > 1 &&
      timesInBetween[0] &&
      timesInBetween[0].text === 'Closed'
    ) {
      timesInBetween.shift();
    }
    //if we have no oppning times, just add 'CLOSED' label to the picker
    if (isDefined(store) && timesInBetween.length === 0) {
      timesInBetween.push({ text: 'Closed', value: null });
    }
    return timesInBetween;
  }
  storeTimes.forEach((storeT, i, arr) => {
    returnTimesInBetween(storeT?.start, storeT?.end, i);
    let minH = parseInt(minDT.split(':')[0]);
    let minM = parseInt(minDT.split(':')[1]);
    let endTimeH = parseInt(storeT?.end.split(':')[0]);
    let endTimeM = parseInt(storeT?.end.split(':')[1]);
    let minTime = date.hours(minH).minutes(minM);
    let timeEnd = date.hours(endTimeH).minutes(endTimeM);
    if (i < arr.length - 1 && arr.length > 0 && moment(minTime).isSameOrBefore(timeEnd)) {
      timesInBetween.push({ text: 'Closed', value: null });
    }
  });

  //remove 'CLOSED' label if that is first time
  if (
    timesInBetween &&
    timesInBetween.length > 1 &&
    timesInBetween[0] &&
    timesInBetween[0].text === 'Closed'
  ) {
    timesInBetween.shift();
  }
  if (timesInBetween.length === 0 && selectedRestaurant) {
    timesInBetween.push({ text: 'Closed', value: null });
  }
  return timesInBetween;
};

class ClickAndCollect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRestaurant: null,
      pickTime: null,
      selectedTimeOption: null,
      error: '',
      showRestaurants: false,
      showTime: false,
      isLocationAllowed: false,
      orderPassed: false,
    };
    this.timeSlotRef = React.createRef();
    this.timePickerOptions = {};
  }

  componentDidMount() {
    Basket.setOrderType(this.props.deliveryOption && this.props.deliveryOption.id ? this.props.deliveryOption.id : 'collection');
    const deliveryOption = checkForDeliveryOption(this.props.deliveryOption, '/click-and-collect');
    if (deliveryOption) {
      this.props.dispatch(getRestaurants());
      this.props.dispatch(setDeliveryOption(deliveryOption));
    }
    if (Basket.getMenu()) {
      this.setState({ selectedIkentooMenu: Basket.getMenu() });
    }
    this.position();
  }

  selectRestaurant = (data) => {
    const { restaurants, profile } = this.props;
    this.setState(
      { selectedRestaurant: data, showRestaurants: false, showTime: true, pickTime: null },
      () => {
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === data.id));
        Basket.setServicePercentage(0)
        Basket.setCollectionTime(null);
        Basket.setOrderType(this.props.deliveryOption && this.props.deliveryOption.id ? this.props.deliveryOption.id : 'collection');
        this.refreshTimeSlot();
      },
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.deliveryOption !== this.props.deliveryOption) {
      checkForDeliveryOption(
        this.props.deliveryOption ? this.props.deliveryOption : Basket.getDeliveryOption(),
        '/click-and-collect',
      );
    }
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
      }
    }
  }

  continueOnMenu = (ikentooMenu) => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant, pickTime } = this.state;

    if (selectedRestaurant && (ikentooMenu || selectedIkentooMenu)) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );

      if (pickTime === 'asap') {
        Basket.setCollectionTime(null);
      }

      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu || ikentooMenu, businessLocationId));
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };

  chooseMenusForLocation = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedRestaurant, pickTime } = this.state;
    if (selectedRestaurant && pickTime) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      let cutoffTimeRes = moment().unix();
      Basket.setCutoffTime(cutoffTimeRes);
      dispatch(
        getIkentooMenusForLocation(businessLocationId, {
          pickTime,
          json_time_selector: choosenRestaurant ? (this.props.scheduled && choosenRestaurant?.active_menu?.scheduled_collection) ? [{
            menuId: choosenRestaurant?.active_menu?.scheduled_collection?.menu_id,
            availability: Object.keys(choosenRestaurant?.active_menu?.scheduled_collection?.slots).map(slot => {
              if (choosenRestaurant?.active_menu?.scheduled_collection?.slots[slot].start_time) {
                return {
                  d: weekdays[slot],
                  start: choosenRestaurant?.active_menu?.scheduled_collection?.slots[slot].start_time,
                  end: choosenRestaurant?.active_menu?.scheduled_collection?.slots[slot].end_time,
                }
              }
            })
          }] : choosenRestaurant.json_time_selector : [],
        }),
      );
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
      this.setState({ orderPassed: true });
    } else if (!selectedRestaurant) {
      this.setState({ error: 'Please select location' });
    } else {
      this.setState({ error: 'Please select pickup time' });
    }
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({
          isLocationAllowed: true,
        });
      },
      (err) => console.log(err),
    );
  };

  formatDataForSelect = (stores) => {
    if (this.props.scheduled) {
      stores.forEach((store) => {
        store.opened = (!store.can_scheduled_collection_order || !store.active_menu?.scheduled_collection || store.json_opening_time_info.length === 0) ? 'Closed' : 'Open';
      });
    } else {
      stores.forEach((store) => {
        const currentDT = moment();
        let minDT = currentDT;
        if (store && isDefined(store.order_slot_lead_time)) {
          minDT.add(store.order_slot_lead_time, 'minutes');
        }

        const timeData = formatDataForTime(store, minDT, store.id, false, false);
        store.opened = timeData.length === 1 && timeData[0].text === 'Closed' ? 'Closed' : 'Open';
      });
    }
    if (!this.state.isLocationAllowed) {
      stores.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    }
    return stores;
  };

  selectStore = (store) => {
    this.setState({ selectedRestaurant: store });
  };

  selectTime = (time) => {
    this.setState({ pickTime: time });
  };

  formatTime = (selectedTime, date) => {
    let h = parseInt(selectedTime.split(':')[0]);
    let m = parseInt(selectedTime.split(':')[1]);
    return moment(date).hours(h).minutes(m);
  };

  parseSelectedTime(selected) {
    let selectedTimeSlot = selected.match(/\d{1,2}:\d{2} (AM|PM)/)?.[0] || 'CLOSED';
    const selectedDate = selected.replace(' ' + selectedTimeSlot, '');
    const selectedTime = selectedDate === "init" ? (this.timePickerOptions ? this.timePickerOptions[Object.keys(this.timePickerOptions)[0]][0] : {}) : this.timePickerOptions[selectedDate].find(
      (option) => option.time === selectedTimeSlot,
    );
    if (selectedTime) {
      this.setState({ pickTime: selectedTime?.snoozed ? null : selectedTime.slot }, () => {
        Basket.setCollectionTime(selectedTime.formattedDT);
        Basket.setOrderType(this.props.deliveryOption && this.props.deliveryOption.id ? this.props.deliveryOption.id : 'collection');
      });
    }
  }

  formatTimesForTree = (times) => {
    const groupByMonth = groupBy(['date']);
    const grouped = groupByMonth(times);
    return grouped;
  };

  formatPickupTimes = (store) => {
    const hasCutOff = getConfig().delivery.filter(option=>option.id=='scheduled-collection')[0]?.cutOff
    const formatSelectOptionDays = [];
    const daysAhead = (this.props.scheduled ? store?.active_menu?.scheduled_collection?.days_ahead : getConfig().general.collectAheadDays) || 7;
    let picker = [];
    Array(daysAhead)
      .fill('')
      .forEach((day, i) => {
        let formatDay = moment().add(i, 'days');
        if (i > 0) {
          formatDay = moment()
            .add(i, 'days')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        }

        if (this.props.scheduled) {
          formatDay = moment()
            .add(i, 'days')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        }

        let formatDayName = formatDay.format('dddd');
        formatSelectOptionDays.push({ formatDayName, formattedDT: formatDay });
      });

    formatSelectOptionDays.map((option, i) => {
      if (store && isDefined(store.order_slot_lead_time) && !this.props.scheduled) {
        option.formattedDT.add(store.order_slot_lead_time, 'minutes');
      }

      const daySlot =
        formatDataForTime(
          store,
          option.formattedDT,
          this.state.selectedRestaurant,
          false,
          false,
          this.props.scheduled
        ) || [];

      daySlot.forEach((slot) => {
        let h = parseInt(slot.text.split(':')[0]);
        let m = parseInt(slot.text.split(':')[1]);
        let formattedDT = moment(option.formattedDT.hours(h).minutes(m));

        picker.push({
          formattedDT: formattedDT,
          // value: formattedDT.toDate(),
          date: formattedDT.format('ddd, DD MMM'),
          time: formattedDT.format('h:mm A'),
          slot: slot.text,
        });
      });
    });

    let unavailable_dates = null
    if (this.props.scheduled && store?.active_menu?.scheduled_collection) {
      unavailable_dates = store?.active_menu?.scheduled_collection.unavailable_dates.map(slot => moment(slot, 'DD/MM/YYYY').format('ddd, DD MMM'))
    }

    const formattedTreeDataSource = this.formatTimesForTree(picker);
    Object.keys(formattedTreeDataSource).map((day, index) => {
      if (formattedTreeDataSource[day][0].slot === 'Closed') {
        if (this.props.scheduled) {
          delete formattedTreeDataSource[day]
        } else {
          formattedTreeDataSource[day][0] = { time: 'CLOSED' };
        }
      } else {
        if (unavailable_dates && unavailable_dates.length > 0 && unavailable_dates.indexOf(day) > -1) {
          formattedTreeDataSource[day] = [{ time: 'CLOSED' }];
        }
        const minDT = moment()
        const minDate = minDT.format('HH:mm')
        const currentDay = minDT.format('ddd, DD MMM')
        let slot = store?.active_menu?.scheduled_collection?.slots[moment(day, 'ddd, DD MMM').format('dddd').toLowerCase()]
          if(currentDay == day && !hasCutOff){
            let cutoff_time = moment().hours(parseInt(minDate.split(':')[0])).minutes(parseInt(minDate.split(':')[1])).seconds(0).add(slot.prep_time, 'hours')
            for (let i = 0; i < formattedTreeDataSource[day].length; i++) {
              if (cutoff_time.isAfter(formattedTreeDataSource[day][i].formattedDT)) {
                
                formattedTreeDataSource[day][i] = false
              }
            }  
            formattedTreeDataSource[day] = formattedTreeDataSource[day].filter(Boolean)
            if(formattedTreeDataSource[day].length==0){
              formattedTreeDataSource[day] = [{ time: 'CLOSED' }];
            }
          }else{
            if (moment().format('ddd, DD MMM') === day && slot?.start_time && this.props.scheduled && store?.active_menu?.scheduled_collection) {
              let cutoff_time = moment().hours(parseInt(slot.start_time.split(':')[0])).minutes(parseInt(slot.start_time.split(':')[1])).seconds(0).add(-slot.prep_time, 'hours')
              if (moment().isAfter(cutoff_time)) {
                delete formattedTreeDataSource[day];
              }
            }
          }
      }
    });


    const snoozedTimes = store?.snoozed_times['scheduled-collection'] || []
    snoozedTimes.map(snoozeTime => {
      let _snoozeTime = moment.utc(snoozeTime, 'ddd, DD MMM YYYY-HH:mm').local()
      let temp_data = formattedTreeDataSource[_snoozeTime.format('ddd, DD MMM')]
      let pos = temp_data?.findIndex(item => item.slot === _snoozeTime.format('HH:mm'))
      if (pos > -1) {
        temp_data[pos] = { ...temp_data[pos], snoozed: true }
      }
    })
    this.timePickerOptions = formattedTreeDataSource;
  };

  refreshTimeSlot = () => {
    if (this.timeSlotRef.current) {
      this.timeSlotRef.current.instance.init();
    }
  };

  render() {
    const {
      __,
      restaurants,
      ikentooMenusForLocation,
      isChooseMenuModalOpen,
      deliveryOption,
      orderProductionMins,
    } = this.props;
    const {
      error,
      showRestaurants,
      showTime,
      selectedRestaurant,
      pickTime,
      selectedIkentooMenu,
      selectedTimeOption,
    } = this.state;
    const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant?.id) || null;
    const stores = restaurants || [];
    const formatedStores = this.formatDataForSelect(stores);
    const mins = isDefined(orderProductionMins) ? orderProductionMins : 15;
    const currentDT = moment();
    let minDT = currentDT;
    this.formatPickupTimes(store);
    const timeOptions = [+-
      { text: 'ASAP - ready in 15 minutes', value: 1, show: false },
    { text: 'Pre-order for later', value: 2, show: true },
    ];
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store ? (this.props.scheduled && store?.active_menu?.scheduled_collection) ? [{
        menuId: store?.active_menu?.scheduled_collection?.menu_id,
        availability: Object.keys(store?.active_menu?.scheduled_collection?.slots).map(slot => {
          if (store?.active_menu?.scheduled_collection?.slots[slot].start_time) {
            return {
              d: weekdays[slot],
              start: store?.active_menu?.scheduled_collection?.slots[slot].start_time,
              end: store?.active_menu?.scheduled_collection?.slots[slot].end_time,
            }
          }
        })
      }] : store.json_time_selector : [],
    );
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    /**/
    return (
      <Layout
        scrollY={false}
        hideSecondToolbar={true}
        headerTitle={__(deliveryOption ? deliveryOption.label : '')}
        noPadding
        className="click-collect-page"
        color="transparent"
      >
        {!showTime && (
          <div className="click-collect-layout">
            <div className="click-collect-wrapper">
              <div className="click-collect-dialog-header">
                <Title>{__(this.props.scheduled ? 'Scheduled Click & Collect Order' : 'Click & Collect Order')}</Title>


                <SmallText>
                  {__('Select a restaurant to collect your order')}
                </SmallText>
              </div>
              <div className="click-collect-locations">
                <IonList>
                  {this.formatDataForSelect(formatedStores).filter(store => store.opened !== 'Closed' && store.is_published && (store.can_collection_order || store.can_scheduled_collection_order)).map((store, index) => (
                    <div key={index} className="click-collect-items">
                      <IonItem
                        onClick={() => this.selectStore(store)}
                        lines="none"
                      >
                        <IonLabel>
                          <div className="bold-description">{__(store.name)}</div>
                          <div className="click-collect-description">{__(store.address)}</div>
                          {store.distance && (
                            <div className="bold-description click-collect-small-description">
                              {store.distance < 1
                                ? store.distance.toFixed(1)
                                : store.distance.toFixed(0)}{' '}
                              {__('miles')} - {__(store.opened)}
                            </div>
                          )}
                        </IonLabel>
                        <IonCheckbox
                          checked={selectedRestaurant && store.id === selectedRestaurant.id}
                          slot="start"
                          color="secondary"
                        />
                      </IonItem>
                    </div>
                  ))}
                </IonList>
              </div>
            </div>
            <div className="click-collect-button">
              <IonButton
                disabled={!selectedRestaurant}
                expand="block"
                color="secondary"
                onClick={() => this.selectRestaurant(selectedRestaurant)}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        )}
        {showTime && (
          <div className="click-collect-layout">
            <div className="click-collect-wrapper">
              <div className="click-collect-dialog-header">
                <Title>{__(this.props.scheduled ? 'Scheduled Click & Collect Order' : 'Click & Collect Order')}</Title>
                <SmallText className="heading">
                  {__('Select a time to collect your order')}
                </SmallText>
              </div>
              {this.state.selectedRestaurant && (
                <mobiscroll.Treelist
                  display="inline"
                  layout="liquid"
                  showInput={false}
                  circular={false}
                  ref={this.timeSlotRef}
                  focusOnClose={false}
                  themeVariant="light"
                  width={(180, 160)}
                  placeholder={__('Select Collection Time')}
                  onSet={(e, instance) => {
                    // Workaround for treelist picker if date is only updated
                    const newInstance = new Object(instance)
                    instance.setVal(newInstance._tempWheelArray, true, true, true)
                    this.parseSelectedTime(instance._tempValue)
                  }}
                  onInit={() => this.parseSelectedTime('init')}
                  scrollLock
                >
                  {Object.keys(this.timePickerOptions).map((date, i) => (
                    <li key={i} data-val={date}>
                      <span className={`item-date-picker ${this.timePickerOptions[date][0].time === 'CLOSED' && 'striked'}`}>
                        {date}
                      </span>
                      <ul>
                        {this.timePickerOptions[date].map((time, j) => (
                          <li key={j} data-val={time.time}>
                            <span className={`item-date-picker ${time.snoozed && 'striked'}`}>
                              {time.time === 'CLOSED' ? 'UNAVAILABLE' : time.time}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </mobiscroll.Treelist>
              )}
            </div>
            <div className="click-collect-button">
              <IonButton
                disabled={!pickTime}
                expand="block"
                color="secondary"
                onClick={() => this.chooseMenusForLocation()}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        )}
        <div
          className="click-collect-pickers-backdrop"
          style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
          onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
        ></div>
        <div className={`click-collect-dialog ${animationMenuClass}`}>
          <Loading transparent> {null} </Loading>
          <div className="click-collect-dialog-layout sc-ion-modal-md">
            <div className="click-collect-dialog-header">
              <h3>{__('Choose menu')}</h3>
            </div>
            <div
              className="click-collect-dialog-closer"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
            >
              <ion-icon
                name="close"
                role="img"
                className="md hydrated"
                aria-label="close"
              ></ion-icon>
            </div>
            <div className="click-collect-dialog-content">
              <IonList lines="none">
                <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                  {menus.length === 0 ? (
                    <NoData />
                  ) : (
                    menus.map((menu) => {
                      const { ikentooMenuId, menuName } = menu;
                      return (
                        <IonItem key={ikentooMenuId} lines="full">
                          <div tabIndex="-1"></div>
                          <IonLabel className="ion-text-wrap">
                            <Sectiontitle>{menuName}</Sectiontitle>
                          </IonLabel>
                          <IonRadio color="secondary" slot="start" value={ikentooMenuId} />
                        </IonItem>
                      );
                    })
                  )}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="click-collect-dialog-action">
              {error ? (
                <IonItem>
                  <div tabIndex="-1"></div>
                  <FieldError className="field-error" value={__(error)} />
                </IonItem>
              ) : null}
              <IonButton
                disabled={pickTime && menus.length > 0 ? false : true}
                expand="block"
                color="secondary"
                onClick={() => this.continueOnMenu()}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isChooseMenuModalOpen, orderProductionMins } = state.common;
  const { restaurants, ikentooMenu, isShowTimePicker, ikentooMenusForLocation } = state.restaurants;
  const { deliveryOption } = state.orders;
  return {
    auth,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    ikentooMenusForLocation: ikentooMenusForLocation || [],
    profile: state.profile.profile,
    isShowTimePicker: isShowTimePicker,
    deliveryOption,
    orderProductionMins: orderProductionMins,
  };
};

export default connect(stateToProps)(withTranslation(ClickAndCollect));
