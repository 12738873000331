import React from 'react';
import { IonCheckbox, IonItem, IonLabel, IonNote } from '@ionic/react';
import Basket from '../../lib/basket';

const { formatPrice } = Basket;

export const SubscriptionLine = (__, clickHandler, key, isChecked, name, description, price) => (
  <IonItem lines="none" key={key}>
    <IonCheckbox color="tertiary" slot="start" checked={isChecked} onClick={clickHandler} />
    <IonLabel>
      <IonNote className="tertiary-text">{name}</IonNote>
      <IonLabel><strong>{description}</strong></IonLabel>
    </IonLabel>
    <IonNote className="ion-text-right" slot="end">
      <IonLabel><strong>{formatPrice(price)}</strong></IonLabel>
      <span className="tertiary-text">{__('per month')}</span>
    </IonNote>
  </IonItem>
);
