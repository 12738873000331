import React from 'react';
import {
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonButton,
  IonAlert,
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { Title, SmallText, Spacer, Sectiontitle, NormalText } from '../../components/common';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import {
  forwardTo,
  insertIntoArray,
  goBack,
  isWebConfig /*, isEmptyObject*/,
} from '../../lib/utils';
import { Capacitor } from '@capacitor/core';
import { getConfig } from '../../appConfig';
import DrawPayButton from '../../components/drawPayButton';
import CheckoutPay from './CheckoutPay';
import './index.css';

const { getMobile, setMobile } = Basket;

const isWeb = () => Capacitor.platform === 'web';
class Checkout extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    const defaultCard =
      this.props.cards.length > 0 &&
      this.props.profile.cardToken &&
      this.props.cards.find((card) => card.id === this.props.profile.cardToken);
    this.state = {
      selectedCard: defaultCard,
      defaultCard: defaultCard,
      selectedJudopayCard: null,
      collectedModal: false,
      collectedPay: this.props.cards.length === 0,
      showAllCards: false,
    };
  }

  componentDidMount() {
    const { profile } = this.props;
    const { google } = window;
    if (getConfig().payment === 'judopay') {
      let judoPayCardsLen = profile.judo_pay_cards.length - 1;
      this.changeSelectedPaymentCard(judoPayCardsLen);
    }
    if (profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }
    window.googlePayClient = new google.payments.api.PaymentsClient({ environment: 'PRODUCTION' });
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    if (!this.state.collectedPay) {
      let judoPayCardsLen = profile.judo_pay_cards.length - 1;
      if (prevProps.profile.cardToken !== profile.cardToken) {
        this.changeSelectedPaymentCard(profile.cardToken);
      }
      if (
        getConfig().payment === 'judopay' &&
        prevProps.profile.judo_pay_cards.length !== profile.judo_pay_cards.length
      ) {
        this.changeSelectedPaymentCard(judoPayCardsLen);
      }
    }
  }

  changeSelectedPaymentCard = (cardId) => {
    if (cardId !== 'collectedPay') {
      const selectedCard = this.props.cards.find((card) => card.id === cardId);
      this.setState({ selectedCard, defaultCard: selectedCard, collectedPay: false }, () => {
        Basket.changeSelectedCard(cardId);
      });
    } else {
      this.setState({ collectedPay: true, selectedCard: null }, () => {});
    }
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipContactDetails
    ) {
      forwardTo('/order', { skipBackToThePreviousPage: true });
    } else if (this.props.location.pathname === '/checkout') {
      forwardTo('/order', { skipBackToThePreviousPage: true });
    } else {
      goBack();
    }
  };

  showCollectedModal(modalOpen) {
    this.setState({ collectedModal: modalOpen });
  }

  render() {
    const { __, cards, clientSecret, dispatch, location, profile } = this.props;

    const { collectedModal, showAllCards, defaultCard, collectedPay } = this.state;
    const basketInstance = this.props.basketInstance || Basket;
    const orderType = basketInstance.getOrderType();
    return (
      <Loading transparent>
        <Layout
          hideSecondToolbar={true}
          color="white"
          headerTitle={__('Checkout')}
          backHandler={this.backHandler}
        >
          {!showAllCards ? (
            <div className="flex-row-wrapper absolute-content">
              <Title className="no-margin-bottom">{__('Checkout')}</Title>
              {/* <SmallText color="primary">{ __('Select Payment Card') }</SmallText> */}
              <NormalText color="primary" className=" thiner-text">
                {__('Confirm the payment method to use to complete')}
              </NormalText>
              <Spacer size={1} />
              <div className="scrollable-y">
                <IonList lines="full">
                  <IonRadioGroup
                    onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                    value={this.state.selectedCard ? this.state.selectedCard.id : 'collectedPay'}
                  >
                    {cards.length > 0 ? (
                      <IonItem key={defaultCard?.id}>
                        <div tabIndex="-1"></div>
                        <IonLabel className="ion-text-wrap">
                          <NormalText color="primary" className="single-item bold">
                            {defaultCard?.name || ''}
                          </NormalText>
                          <Sectiontitle className="no-margin">
                            •••• •••• •••• {defaultCard?.last4}
                          </Sectiontitle>
                          <SmallText color="primary">
                            <IonButton
                              expand="block"
                              fill="clear"
                              color="primary"
                              className="link underlined"
                              onClick={() =>
                                this.setState({ showAllCards: true, selectedCard: defaultCard })
                              }
                            >
                              <div className="link-lefted">{__('Select a different card')}</div>
                            </IonButton>
                          </SmallText>
                        </IonLabel>
                        <IonRadio
                          slot="start"
                          color={isWebConfig() ? 'secondary' : 'white'}
                          value={defaultCard?.id + ''}
                        />
                      </IonItem>
                    ) : (
                      <IonItem>
                        <div tabIndex="-1"></div>
                        <IonLabel className="ion-text-wrap">
                          <SmallText color="primary">
                            <IonButton
                              expand="block"
                              fill="clear"
                              color="primary"
                              className="link underlined"
                              onClick={() => forwardTo('/card-add', { addCardFromCheckout: true })}
                            >
                              <div className="link-lefted">{__('Add a payment card')}</div>
                            </IonButton>
                          </SmallText>
                        </IonLabel>
                        <IonRadio slot="start" color={isWebConfig() ? 'secondary' : 'white'} onClick={() => forwardTo('/card-add', { addCardFromCheckout: true })} />
                      </IonItem>
                    )}
                    {orderType === 'Click & Collect' && getConfig().appType.hasCollectedPay &&
                    <IonItem key="collectedPay">
                      <div tabIndex="-1"></div>
                      <IonLabel className="ion-text-wrap">
                        <NormalText color="primary" className="single-item bold">
                          {__('Pay on Collection')}
                        </NormalText>
                      </IonLabel>
                      <IonRadio
                        slot="start"
                        color={isWebConfig() ? 'secondary' : 'white'}
                        value="collectedPay"
                      />
                    </IonItem>
  }
                  </IonRadioGroup>
                </IonList>
              </div>
              <div className="flex-min">
                {/* {getConfig().flags.payOnCollection && Basket.getOrderType() === 'charter-delivery' ? (
                <IonButton
                  onClick={() => this.showCollectedModal(true)}
                  expand="block"
                  color="secondary"
                >
                  {__('Pay') + ' ' + Basket._getTotal() + ' ' + __('On Collection')}
                </IonButton>
              ) : null} */}
                <IonButton
                  color="secondary"
                  disabled={cards.length === 0}
                  onClick={() =>
                    collectedPay ? this.showCollectedModal(true) : Basket.createOrder()
                  }
                  expand="block"
                >
                  {collectedPay ? __('PLACE ORDER') : __('Pay ') + Basket._getTotal()}
                </IonButton>
                <h2 className="pay-divider"><span>{__('OR')}</span></h2>
                {isWeb() ? (
                  <CheckoutPay
                    clientSecret={clientSecret}
                    dispatch={dispatch}
                    __={__}
                    location={location}
                    profile={profile}
                  />
                ) : (
                  <DrawPayButton __={__} googlePayClient={window.googlePayClient} />
                )}
              </div>
            </div>
          ) : (
            <div>
              <Title className="no-margin-bottom">{__('Select payment card')}</Title>
              <NormalText color="primary" className=" thiner-text">
                {__('Confirm the payment method to use to complete')}
              </NormalText>
              <Spacer size={1} />
              <div className="scrollable-y">
                <IonList lines="full">
                  {getConfig().payment === 'judopay' ? (
                    <IonRadioGroup
                      onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                      value={this.state.selectedCard.id}
                    >
                      {cards.map((card, id) => {
                        const { cardLastFour, cardExpiry, cardType, name } = card;
                        let cardEx = insertIntoArray(cardExpiry, 2, '/');
                        return (
                          <IonItem key={id}>
                            <div tabIndex="-1"></div>
                            <IonLabel className="ion-text-wrap">
                              <NormalText color="primary" className="single-item bold">
                                {name || ''}
                              </NormalText>
                              <Sectiontitle className="no-margin">
                                •••• •••• •••• {cardLastFour}
                              </Sectiontitle>
                              <SmallText color="primary">
                                {__(cardType)} - {cardEx}
                              </SmallText>
                            </IonLabel>
                            <IonRadio
                              slot="start"
                              color={isWebConfig() ? 'secondary' : 'white'}
                              value={id + ''}
                              onIonSelect={() => {
                                this.setState({ selectedCard: card }, () => {
                                  Basket.changeSelectedCard(id);
                                });
                              }}
                            />
                          </IonItem>
                        );
                      })}
                    </IonRadioGroup>
                  ) : (
                    <IonRadioGroup
                      onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                      value={this.state.selectedCard?.id}
                    >
                      {cards.map((card) => {
                        const { id, last4, brand, exp_month, exp_year, name } = card;
                        return (
                          <IonItem key={id}>
                            <div tabIndex="-1"></div>
                            <IonLabel className="ion-text-wrap">
                              <NormalText color="primary" className="single-item bold">
                                {name}
                              </NormalText>
                              <Sectiontitle className="no-margin">
                                •••• •••• •••• {last4}
                              </Sectiontitle>
                              <SmallText color="primary">
                                {__(brand)} - {exp_month}/{exp_year}
                              </SmallText>
                            </IonLabel>
                            <IonRadio
                              slot="start"
                              value={id}
                              color={isWebConfig() ? 'tertiary' : 'white'}
                              onIonSelect={() => {
                                this.setState({ selectedCard: card, collectedPay: false }, () => {
                                  Basket.changeSelectedCard(id);
                                });
                              }}
                            />
                          </IonItem>
                        );
                      })}
                    </IonRadioGroup>
                  )}
                </IonList>
                <IonButton
                  fill="clear"
                  color="secondary"
                  className="link underlined"
                  onClick={() => forwardTo('/card-add', { addCardFromCheckout: true })}
                >
                  {__('Add New Payment Card')}
                </IonButton>
              </div>
              <div className="flex-min">
                <IonButton
                  color="secondary"
                  disabled={cards.length === 0}
                  onClick={() => this.setState({ showAllCards: false })}
                  expand="block"
                >
                  {__('Select Payment Card')}
                </IonButton>
              </div>
            </div>
          )}
        </Layout>
        <IonAlert
          isOpen={collectedModal}
          onDidDismiss={() => this.showCollectedModal(false)}
          header={__('Pay on Collection')}
          message={__(
            'You agree to pay the amount shown before collecting your order. Earned loyalty will be updated after payment',
          )}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.showCollectedModal(false),
            },
            {
              text: __('Confirm'),
              handler: () => {
                Basket.createOrder('collectedPay');
                this.showCollectedModal(false);
              },
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    cards: store.orders.cards || [],
    clientSecret: store.orders.clientSecret,
    profile: store.profile.profile || {},
  };
};

export default connect(mapStateToProps)(withTranslation(Checkout));
