import { getEnv } from './lib/env.js';

let appConfig = {
	theme: {
		nav: {
			label: "GW Brewery"
		},
		showHeaderOnAuthRoutes: true,
		showInputIconsOnLogin: false,
		routesWithoutStaticHeader: ['/item-details']
	},
	services: {
		google_maps: {
			google_api_key: getEnv('GOOGLE_API_KEY')
		}
	},
	api: {
		baseURL: getEnv('BASE_URL'),
		wordPressBaseURL: 'https://hybridapp.co.uk',
	},
	general: {
		clientName: "GW Brewery",
		appExitRoutes: ['/', '/home', '/dashboard'],
		authRoutes: ['/login', '/register', '/reset-password'],
		routesWithBackButton: [
			'/cards',
			'/card-add',
			'/allergen-information',
			'/order-summary',
			'/vouchers',
			'/order-completed',
			'/apply-vouchers',
			'/terms',
			'/privacy',
			'/faq',
			'/contact-details',
			'/click-and-collect',
			'/scheduled-click-and-collect',
			'/scheduled-delivery',
			'/order-to-table',
			'/history-details',
			'/delivery',
			'/my-membership',
			'/membership-terms',
			'/allergens'
		],

		isReduxDevToolsOn: true,
		isWebPlatform: false

	},
	appType: {
		hasOrdering: true,
		hasLoyalty: true,
		hasEmailValidationEnabled: true,
		hasCampaignManager: true,
		referAFriend: true,
		hasCancelOrder: false,
		hasUnlockRewardsOnLoyalty: false,
		isReward: false,
		oldLayoutConfirmationScreens: false,
		hasCollectedPay: false
	},
	configS3: {
		imageNamePrefix: 'hyfive_profile_image_',
		bucketName: 'hybrid-apps-profile-images',
		region: 'eu-west-1',
		accessKeyId: getEnv('S3_ACCESS_KEY_ID'),
		secretAccessKey: getEnv('S3_SECRET_ACCESS_KEY')
	},

	firebaseConfig: {
		apiKey: getEnv('FIREBASE_API_KEY'),
		authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
		databaseURL: getEnv('FIREBASE_DATABASE_URL'),
		projectId: getEnv('FIREBASE_PROJECT_ID'),
		storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
		messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
		appId: getEnv('FIREBASE_APP_ID'),
	},
	payment: 'stripe'
}

export const updateConfig = (newConfig) => (appConfig = newConfig);

export const getConfig = () => appConfig;

export default appConfig;
