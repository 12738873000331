import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate.js';
import noDataImg from '../../assets/images/backgrounds/history.png';
import './index.css';

class NoDataSecond extends Component {
  render() {
    const { __, label, className, style } = this.props;
    return (
      <p className={className || ''} style={{ textAlign: 'center', ...(style || {}) }}>
        <img src={noDataImg} className="no-data-img" />
        <div>{__(label || 'No data')}</div>
      </p>
    );
  }
}

export default withTranslation(NoDataSecond);
