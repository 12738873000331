import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import { SmallText, Spacer, StrongText, Title } from '../common';
import { makeKey, isDefined, forwardTo, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { removeVoucher, setModal } from '../../store/actions';
import Modal from '../../components/modal';
import './index.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ServiceCharge } from '../../screens/service-charge';
import { getConfig } from '../../appConfig';

class OrderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceChargeModalOpen: false,
      showCharge: false,
    };
  }
  handleServiceChargeModal = (flag) => {
    if (isWebConfig()) {
      this.setState({ serviceChargeModalOpen: flag, showCharge: !flag });
    } else {
      forwardTo('/service-charge');
    }
  };

  drawSubItems = (basketItem = {} , basketItemIndex,handleOrderItemClick) => {
    const { selectedChoices, quantity } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;

    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <IonRow
              className="bordered-bottom sub-items-row"
              key={makeKey(
                choiceGroupIndex,
                choiceIndex,
                sku,
              )} onClick={ () => handleOrderItemClick(basketItem, basketItemIndex) }
            >
              <IonCol size="50px"></IonCol>
              <IonCol className="paddLR grow">
                {quantity} x {Basket.getProductName(choice, profile)}
              </IonCol>
              <IonCol className="righted paddLR">
                {basketInstance.calculateSubItemPrice(choice, quantity) !== 0
                  ? basketInstance._calculateSubItemPrice(choice, quantity)
                  : null}
              </IonCol>
            </IonRow>
          );
        });
      });
    }
    return null;
  };

  render() {
    const {
      handleOrderItemClick,
      __,
      history,
      profile,
      showAddItems,
      dispatch,
      isRemoveVoucherModalOpen,
    } = this.props;
    const { serviceChargeModalOpen } = this.state;

    const basketInstance = this.props.basketInstance || Basket;
    const type = this.props.type || 'order';
    const appliedPoints = basketInstance.getAppliedPoints();
    const deliveryPrice = basketInstance.getDeliveryPrice();
    const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
    const appliedVoucher = basketInstance.getAppliedVocuher();
    const orderType = basketInstance.getOrderType();
    let location =
      history && history.location && history.location.pathname === '/history-details'
        ? true
        : false;
    return (
      <IonGrid className="order-content-wrapper paddL">
        {basketInstance.getItems().map((basketItem, basketItemIndex) => {
          const { quantity, item, instructions } = basketItem;
          return (
            <div key={basketItemIndex} className="basket-item-wrapper">
              <IonRow
                key={makeKey(basketItemIndex, item.sku)}
                onClick={() => {
                  if (handleOrderItemClick) {
                    handleOrderItemClick(basketItem, basketItemIndex);
                  }
                }}
              >
                <IonCol className="paddLR grow text-start">
                  {quantity} x {Basket.getProductName(item, profile)}
                </IonCol>
                <IonCol className="righted paddLR text-start">
                  {basketInstance.calculateItemPriceByIndex(basketItemIndex, false) > 0
                    ? basketInstance._calculateItemPriceByIndex(basketItemIndex, false)
                    : null}
                </IonCol>
              </IonRow>
              {this.drawSubItems(basketItem, basketItemIndex,handleOrderItemClick)}
              {instructions && instructions !== '' ? (
                <IonRow>
                  <IonCol>
                    <SmallText>{__('Special Notes')}:</SmallText>
                  </IonCol>
                  <IonCol className="righted instruction-grow">
                    <SmallText>{instructions}</SmallText>
                  </IonCol>
                </IonRow>
              ) : null}
            </div>
          );
        })}
        {showAddItems ? (
          <IonRow className="righted add-items-row">
            <IonButton
              size="small"
              color="tertiary"
              className="add-items-btn round-button"
              onClick={() => forwardTo('/order')}
            >
              + {__('Add Items')}
            </IonButton>
          </IonRow>
        ) : null}
           {basketInstance.service_percentage > 0 && orderType === 'Table' && getConfig().general.hasServiceCharge && basketInstance.getServiceChargeValue() == null ? (
          <div key="service_charge" className="basket-item-wrapper pointer">
            <IonRow
              className="basket-item-service-charge-row service-charge-item"
              onClick={() => this.handleServiceChargeModal(true)}
            >
              <>
                <IonCol className="paddLR grow ">
                  {__('Service charge')}&nbsp;{basketInstance._getServicePercentage()}
                </IonCol>
                <IonCol className="righted paddLR self-center ">
                  {basketInstance.getServiceChargeValue() > 0
                    ? basketInstance.getFormatedServiceChargeValue()
                    : basketInstance.getServiceCharge(true)}                </IonCol>
              </>
            </IonRow>
          </div>
        ) : null}
        {orderType === 'Table' && basketInstance.getServiceChargeValue() > 0 ? (
          <div key="service_charge" className="basket-item-wrapper pointer">
            <IonRow
              className="basket-item-service-charge-row service-charge-item"
              onClick={() => this.handleServiceChargeModal(true)}
            >
              <>
                <IonCol className="paddLR grow ">
                  {__('Service charge')}&nbsp;{basketInstance._getServicePercentage()}
                </IonCol>
                <IonCol className="righted paddLR self-center ">
                  {basketInstance.getFormatedServiceChargeValue()}
                </IonCol>
              </>
            </IonRow>
          </div>
        ) : null}
        {deliveryPriceUnformated > 0 ? (
          <IonRow className="bordered-bottom">
            <IonCol className="paddLR grow text-start">{__('Delivery Charge')}</IonCol>
            <IonCol className="righted paddLR">
              {/* basketInstance._calculatePointsAppliedPrice(null, true) */} {deliveryPrice}
            </IonCol>
          </IonRow>
        ) : null}
       {(appliedVoucher.length || basketInstance.discount || appliedPoints > 0) && (
          <IonRow
            className={appliedVoucher.length > 0 ? 'bordered-bottom' : 'subtotal-bordered-bottom'}
          >
            <IonCol className="bold paddLR grow">{__('Subtotal')}</IonCol>
            <IonCol className="righted paddLR">{basketInstance._getSubTotal()}</IonCol>
          </IonRow>
        )}
        {appliedVoucher &&
          appliedVoucher.length > 0 &&
          appliedVoucher.map((voucher) => {
            let voucherName = voucher.name;
            if (voucher && voucher.reward && voucher.reward.name) {
              voucherName = voucher.reward.name;
            }
            return (
              
              <div key={voucher.id}>
                <Spacer size={1} />
                <Title className="centered">{__('Vouchers')}</Title>
                <IonRow
                  className="bordered-bottom"
                  onClick={() =>
                    location ? null : dispatch(setModal('isRemoveVoucherModalOpen', true))
                  }
                >
                  <IonCol className="paddLR grow ">{__(voucherName)} </IonCol>
                  <IonCol className="righted paddLR">
                    {basketInstance._calculateAppliedVocuhersPrice(true, null)}
                  </IonCol>
                </IonRow>
                <Modal
                  className="remove-apply-voucuher"
                  title={__('Do you want to remove voucher') + ' ?'}
                  actionLabel={__('Yes')}
                  action={() => dispatch(removeVoucher(voucher.id))}
                  isOpen={!!isRemoveVoucherModalOpen}
                  onDidDismiss={() => dispatch(setModal('isRemoveVoucherModalOpen', false))}
                  noContent={true}
                />
              </div>
            );
          })}
     
 

        {basketInstance.discount && (
          <IonRow className="bordered-bottom">
            <IonCol className="paddLR grow">{basketInstance.discount.title}</IonCol>
            <IonCol className="righted paddLR">
              -{basketInstance.formatPrice(basketInstance.calculateMembershipDiscount())}
            </IonCol>
          </IonRow>
        )}

        <>

          {isDefined(appliedPoints) && appliedPoints > 0 ? (
            <IonRow
              className="total-bordered-bottom"
              onClick={() => {
                if (type === 'order' && history) {
                  history.push('/apply-points');
                }
              }}
            >
              <IonCol className="paddLR grow">
                {appliedPoints}&nbsp;{__('Points Redeem')}
              </IonCol>
              <IonCol className="righted paddLR">
                {basketInstance._calculatePointsAppliedPrice(null, true)}
              </IonCol>
            </IonRow>
          ) : null}
        </>

        <IonRow className={deliveryPriceUnformated > 0 ? '' : 'total-bordered-bottom'}>
          <IonCol className="paddLR text-start">
            <StrongText className="uppercase paddLR ">
              <strong>{__('Total')}</strong>
            </StrongText>
          </IonCol>
          <IonCol className="righted paddLR"><strong>{basketInstance._getTotal(true)}</strong></IonCol>
        </IonRow>
        {(basketInstance.service_percentage === 0 && orderType === 'Table' && getConfig().general.hasServiceCharge && basketInstance.getServiceChargeValue() === null) ? (
          <div key="service_charge" className="basket-item-wrapper">
            <IonButton
              expand="block"
              fill="clear"
              color="primary"
              className="link underlined basket-item-service-charge-row"
              onClick={() => this.handleServiceChargeModal(true)}
            >
              add service charge
            </IonButton>
          </div>
        ) : null}
        <Modal
          cssClass="service-charge-modal"
          isOpen={serviceChargeModalOpen}
          onDidDismiss={() => this.handleServiceChargeModal(false)}
        >
          <ServiceCharge
            handleServiceChargeModal={this.handleServiceChargeModal}
            serviceChargeModalOpen={serviceChargeModalOpen}
          />
        </Modal>
      </IonGrid>

    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
