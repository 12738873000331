import React from 'react';
import { Title, NormalText } from '../common';
import { isEmptyObject } from '../../lib/utils';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';

const { getRestauranName, getOrderDate, getOrderTime, getTableNumber } = Basket;

const findSelectedSlot = (dayInWeek, time) => {
  const restaurant = Basket.getRestaurant();
  const selectedDay = restaurant.delivery_times_json.slots[dayInWeek];
  const selectedSlot = selectedDay.find(
    (day) =>
      moment(day.start_time, 'HH:mm').format('HH:mm') === moment(time, 'HH:mm a').format('HH:mm'),
  );
  return (
    moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') +
    ' - ' +
    moment(selectedSlot.end_time, 'HH:mm').format('h:mm a')
  );
};

const ContentHeader = ({ __, deliveryOption, orderType, deliveryAddress, pickUpPoint }) => {
  let dateName = moment(getOrderDate(), 'dddd Do MMM').format('ll')

  let dayInWeek = moment(getOrderDate(), 'dddd Do MMM').format('dddd');
  const deliveryLabel =
    deliveryOption && (deliveryOption.label === 'Scheduled Delivery' || deliveryOption.label === 'Order for Delivery')
      ? 'Delivery Order'
      : deliveryOption.label;
  const locationCode = pickUpPoint && pickUpPoint.location_code ? pickUpPoint.location_code : '';
  const styledText = { flex: 1 };
  if (!orderType || orderType === '') {
    return null;
  }
  if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
    return (
      <>
        <Title className="order-intro-title">
          <strong>{__(deliveryOption.label)}</strong>
        </Title>
        <NormalText className="auto-grid">
          <span>
            {__('Ordering for')} {__('drop-off')} {__('at')}{' '}
            <strong>
              {getRestauranName()} {`(#${locationCode})`},{' '}
            </strong>
          </span>{' '}
          <span>
            {dateName} {__('at')} {getOrderTime()}
            <br />
          </span>
        </NormalText>
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'delivery') {
    return (
      <>
        <Title className="order-intro-title">
          <strong>{__(deliveryLabel)}</strong>
        </Title>
        <NormalText className=" thiner-text auto-grid">
          <span>
            {__('Ordering for')} {__('delivery')} {__('to')}{' '}
            <strong>{deliveryAddress.addressLine1}, </strong>
          </span>{' '}
          {/* {deliveryAddress.place } {deliveryAddress.postalCode }  */}{' '}
          <span>
            {dateName} {__('at')} {findSelectedSlot(dayInWeek, getOrderTime())}
            <br />
          </span>
        </NormalText>
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
    return (
      <>
        <Title className="order-intro-title">
          <strong>{__(deliveryOption.label)}</strong>
        </Title>
        <NormalText className="flex-col-wrapper flex-row-holder">
          {__('Ordering at')} {getRestauranName()} {__('for Table')} {getTableNumber() + '.'}{' '}
          {__('Your order will be brought to you as soon as possible.')}
        </NormalText>
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'charter-delivery') {
    const date = dateName + __(' at ') + getOrderTime('h:mm a');
    const dateRes = dateName !== 'Invalid date' ? date : 'as soon as possible.';
    return (
      <>
        <Title className="order-intro-title">
          <strong>{__(deliveryOption.label)}</strong>
        </Title>
        <NormalText /*className="flex-col-wrapper flex-row-holder"*/ className="">
          <span>
            {__('Ordering at')} {getRestauranName()} {__('for delivery')} {__('to')}{' '}
            <strong>
              {deliveryAddress.addressLine1}, {deliveryAddress.addressLine2},{' '}
            </strong>{' '}
            {deliveryAddress.place}, {deliveryAddress.postalCode}
          </span>
          <span>
            {' '}
            {dateRes}
            <br />
          </span>
          {/* { __('Ordering for')} { getOrderDate()} { __('at')} { getOrderTime()}<br /> */}
          {/* { ' ' + __('from')} { getRestauranName()} */}
          {/* <span style={{ marginBottom: 5 }} className="flex-col-wrapper"><strong>{ __('Collection Location') }</strong> <span className="righted thiner-text">{ getRestauranName() }</span></span>
					<span className="flex-col-wrapper"><strong>{ __('Collection Time') }</strong> <span style={ styledText } className="righted thiner-text">{ charterDeliveryDateAndTime !== 'Invalid date' ? `${getOrderDate('D MMMM YYYY')} ${__('at')}  ${getOrderTime('h:mm a')}` : `${Basket.collection_time}`}</span></span> */}
        </NormalText>
      </>
    );
  } else {
    const date = dateName + __(' at ') + getOrderTime('h:mm a');
    const dateRes = dateName !== 'Invalid date' ? date : 'as soon as possible';
    return (
      <>
        <Title className="order-intro-title">
          <strong>{__(orderType)}</strong>
        </Title>
        <NormalText className="flex-col-wrapper flex-row-holder ">
          <span className="order-header flex-col-wrapper">
            <strong>{__('Collection Location')}</strong>{' '}
            <span className="righted thiner-text">{getRestauranName()}</span>
          </span>
          <span className="order-header flex-col-wrapper">
            <strong>{__('Collection Time')}</strong>{' '}
            <span style={styledText} className="righted thiner-text">
              <span className="inline-block">{dateRes}</span>
            </span>
          </span>
        </NormalText>
      </>
    );
  }
};

export default ContentHeader;
