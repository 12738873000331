import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import HistoryTab from './historyTab';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { getTransactionHistory } from '../../store/actions';
import SwipableTabs from '../../components/swipeableTabs';
import { getConfig } from '../../appConfig';
import './index.css';
import moment from 'moment';
import basket from '../../lib/basket';

const tabLabelMap = {
  ordering: {
    first: 'Loyalty',
    second: 'Orders',
  },
  catalog: {
    first: 'Accrue',
    second: 'Redeem',
  },
};

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getTransactionHistory();
  }

  getTransactionHistory = () => {
    this.props.dispatch(getTransactionHistory());
  };

  getNameOfLabelFirstTab = () => {
    const { hasOrdering } = getConfig().appType;
    const { __, transactionHistory } = this.props;

    const firstTabListAdditional = transactionHistory.map((item) => ({
      ...item,
      label:
        item.business_location_id + '' === '-1'
          ? __('Referral Bonus')
          : item.business_location_id + '' === '-2'
          ? __('Sign up Bonus')
          : item.business_location_id + '' === '-3'
          ? __('Refunded points')
          : hasOrdering
          ? item.stamp_power < 0
            ? __('Redeemed')
            : __('Earned')
          : transactionHistory
              .filter((i) => i.stamp_power >= 0)
              .map((item) => ({ ...item, label: 'Earned' })),
    }));

    return firstTabListAdditional;
  };

  render() {
    const { __, transactionHistory, orderHistory } = this.props;
    const { hasOrdering, hasLoyalty } = getConfig().appType;
    const tabLabels = hasOrdering ? tabLabelMap.ordering : tabLabelMap.catalog;
    const secondTabList = hasOrdering
      ? orderHistory.map((i) => ({
          label: i.restaurant_name + ' #' + i.id,
          transaction_date:i.eat_in? moment(i.created_at).format('DD MMMM YYYY [at] h:mm a') :  basket.getDate(i.collection_time).format('DD MMMM YYYY [at] h:mm a'),
          item: i,
        }))
      : transactionHistory
          .filter((i) => i.stamp_power < 0)
          .map((item) => ({ ...item, label: 'Redeemed' }));
    const firstTabType = 'points';
    const secondTabType = hasOrdering ? 'order' : 'points';
    return (
      <Layout headerTitle={__('History')} hideSecondToolbar={true} noPadding={true} color="transparent" scrollY={false}>
        {hasLoyalty && hasOrdering ? (
          <SwipableTabs
            history={this.props.location}
            tabs={[
              {
                label: __(tabLabels.first),
                tabContent: (
                  <HistoryTab
                    type={firstTabType}
                    transactionHistory={this.getNameOfLabelFirstTab()}
                    getTransactionHistory={this.getTransactionHistory}
                  />
                ),
              },
              {
                label: __(tabLabels.second),
                tabContent: (
                  <HistoryTab
                    type={secondTabType}
                    transactionHistory={secondTabList}
                    getTransactionHistory={this.getTransactionHistory}
                  />
                ),
              },
            ]}
          />
        ) : hasLoyalty ? (
          <HistoryTab
            type={firstTabType}
            transactionHistory={this.getNameOfLabelFirstTab()}
            getTransactionHistory={this.getTransactionHistory}
          />
        ) : hasOrdering ? (
          <HistoryTab
            type={secondTabType}
            transactionHistory={secondTabList}
            getTransactionHistory={this.getTransactionHistory}
          />
        ) : null}
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  return {
    transactionHistory: state.orders.history || [],
    orderHistory: state.orders.orderHistory || [],
  };
};

export default withRouter(withTranslation(connect(stateToProps)(History)));
