import React from 'react';
import {
  IonButton, IonItem, IonInput, /*, isPlatform */
  IonText
} from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import Clickup from '../../components/clickup';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, SmallText, FieldError, StrongText } from '../../components/common';
import { validateForm, isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { sendVoucherCode } from '../../store/actions';
import { forwardTo } from '../../lib/utils';
import QRCode from 'qrcode-react';
import moment from '../../lib/moment';
import './index.css';
import NoDataSecond from '../../components/noDataSecond';
import plateImage from '../../assets/images/icons/eat.png'
// import voucherDummyImg from '../../assets/images/dashboard-03.jpg'

// temporary dummy array
// const dummyVouchers = [
// 	{
// 		id: 'voucher-1',
// 		title: '£5',
// 		subtitle: 'off any meal',
// 		stamp: 'food-1',
// 		className: 'green',
// 		heading: 'Coffee Stamp Reward',
// 		subheading: 'Loyalty Card Free Coffee Reward',
// 		expiration: '03/09/20',
// 		details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
// 		qr: '??/assets/images/qr-dummy.png'
// 	},
// 	{
// 		id: 'voucher-2',
// 		title: '25% off',
// 		subtitle: 'all day tuesday',
// 		stamp: 'food-1',
// 		className: 'red',
// 		heading: 'All Day Tuesday Special',
// 		subheading: '25% off Coupon',
// 		expiration: '03/05/20',
// 		details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
// 		qr: '??/assets/images/qr-dummy.png'
// 	},
// 	{
// 		id: 'voucher-3',
// 		title: 'double',
// 		titleSecondary: 'stamps',
// 		description: 'Monday Lunch between 11.30 - 14.30',
// 		stamp: 'food-1',
// 		className: 'black default-font',
// 		heading: 'Double Stamp Reward',
// 		subheading: 'Loyalty Card Reward',
// 		expiration: '03/05/20',
// 		details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
// 		qr: '??/assets/images/qr-dummy.png'
// 	}
// ]



const VoucherItem = ({ reward, id, className, __, action }) => {
  return (
    <div className={'voucher' + (className ? ' ' + className : '')}>
      <div className='voucher-card-wrapper'>
        <div className='voucher-card-content'>
          <div className='voucher-title-wrapper'>
            <Title className='ellipsis'>{reward.name}</Title>
            <NormalText >{reward.small_print}</NormalText>
          </div>
          <img src={plateImage} alt=' ' />
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="265" height="134.291" viewBox="0 0 265 134.291">
          <g id="Group_2194" data-name="Group 2194" transform="translate(228 -3659)">
            <g id="Group_1988" data-name="Group 1988" transform="translate(-303 3140)">
              <g id="Group_1985" data-name="Group 1985" transform="translate(75 519)">
                <path id="Path_5431" data-name="Path 5431" d="M340,532.429V519H75v13.491c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062V625.6c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v13.491H340V639.862a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324V570.03a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324Z" transform="translate(-75 -519)" />
              </g>
              <g id="Group_1987" data-name="Group 1987" transform="translate(75 519)">
                <g id="Group_1986" data-name="Group 1986" opacity="0.1">
                  <path id="Path_5432" data-name="Path 5432" d="M340,532.429V519H75v13.491c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062V625.6c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v13.491H340V639.862a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324V570.03a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324Z" transform="translate(-75 -519)" />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      { action ?
        <div className="voucher-bar">
          <div className="ellipsis">
            {/* <p className="ellipsis">{ __('Our Great New Voucher Deal!')}</p> */}
            <p className="ellipsis">{__(reward.name)}</p>
          </div>
          <div>
            <IonButton fill="outline" size="small" onClick={() => action(id)}>{ __('View')}</IonButton>
            {/* <IonButton fill="outline" size="small" onClick={() => action(id)}>{__('Redeem')}</IonButton> */}
          </div>
        </div>
        : null}
    </div>
  )
}

class Vouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    if (
      isDefined(this.props.location.state) &&
      isDefined(this.props.location.state.openVoucherModal)
    ) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
      //console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
    }
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data));
    }
  };

  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };

  render() {
    const { __, vouchers, qr_code } = this.props;
    const { voucherCode, selectedVoucher } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
    });
    const clickupContent = (
      <Clickup label={__('Redeem Offer Code')} height="320">
        <div className="absolute-wrapper flex-row-wrapper">
          <div className="padded no-padding-bottom centered scrollable-y">
            <p className="no-margin-top">
              {__('Enter a valid voucher code below to receive your reward')}
            </p>
            <div className="lefted no-padding-bottom voucher-item">
              <IonItem style={{ '--min-height': '32px' }}>
                <div className="inline-input">
                  <IonText class='bold'>{__('Offer Code')}</IonText>
                  <IonInput
                    value={voucherCode}
                    onIonChange={(e) => this.handlerOfVoucherCode(e)}
                    placeholder={__('Enter your offer code')}
                  />
                </div>
                <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
              </IonItem>
            </div>
          </div>
          <div className="padded no-padding-top flex-min">
            <IonButton
              disabled={voucherCode === ''}
              color="secondary"
              expand="block"
              onClick={this.sendCode}
            >
              {__('Submit')}
            </IonButton>
          </div>
        </div>
      </Clickup>
    );
    return (
      <Loading transparent>
        <Layout hideSecondToolbar={true} headerTitle={__('Vouchers')} scrollY={false}>
          <div className="absolute-content flex-row-wrapper">
            <div className="flex-min ion-text-center">
              <Title className='centered'>{__('Voucher Wallet')}</Title>
              <Spacer size={1} />
            </div>
            <div className="scrollable-y">
              {!voucherRes || voucherRes.length === 0 ? (
                <NoDataSecond label="Your vouchers will appear here" />
              ) : null}
              {voucherRes &&
                voucherRes.map((voucher, index) => {
                  return (
                    <VoucherItem
                      key={'vc-' + index}
                      {...voucher}
                      __={__}
                      action={this.viewVoucher}
                    />
                  );
                })}
              <Spacer size={2} />
            </div>
          </div>
          <div className="flex-min">{clickupContent}</div>

          {/* {!isPlatform('ios') ? clickupContent : null} */}
        </Layout>
        {/* {isPlatform('ios') ? clickupContent : null} */}
        <Modal
          className="voucher-modal"
          isOpen={selectedVoucher !== null}
          onDidDismiss={() => this.setState({ selectedVoucher: null })}
          title={__('Voucher Info')}
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div className="centered">

              <VoucherItem
                {...selectedVoucher}
                __={__}
              />

              <Spacer size={2} />
              <div className="voucher-modal-content">
                {selectedVoucher.reward.name ? (
                  <>
                    <Title className="centered uppercase bold no-margin-bottom">{selectedVoucher.reward.name}</Title>
                  </>
                ) : null}
                {selectedVoucher.reward.small_print ? (
                  <div>
                    <NormalText>{selectedVoucher.reward.small_print}</NormalText>
                  </div>
                ) : null}
                {selectedVoucher.reward.expiry_date ? (
                  <div>
                    <NormalText>
                      {__('Expires:')}{' '}
                      {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
                    </NormalText>
                  </div>
                ) : null}
                <Spacer size={1} />
                <div onClick={() => forwardTo('/terms')}>
                  <StrongText className="bold">{__('Terms & Conditions')}</StrongText>
                </div>
                {selectedVoucher.reward.description ? (
                  <div>
                    <SmallText>{__(selectedVoucher.reward.description)}</SmallText>
                  </div>
                ) : null}
                <>
                  {isDefined(qr_code) ? (
                    <div className="qr-holder">
                      <QRCode value={qr_code} size={150} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </>
              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
  };
};

export default connect(stateToProps)(withTranslation(Vouchers));
