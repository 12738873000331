import React from 'react';
import { forwardTo } from '../../lib/utils';

import './index.css';

const PromoBar = ({ type, onDismiss, vouchers }) => {
  const formatCapitalizePromoText = (text) => {
    if (typeof text !== 'string') {
      return '';
    }
    let str = text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
    return str;
  };

  return vouchers && vouchers.length > 0 && vouchers[0].reward ? (
    <>
      <div className="promo-bar-scrim" onClick={onDismiss ? onDismiss : null}></div>
      <div
        className={'promo-bar ' + (type || 'info')}
        onClick={() => forwardTo('/vouchers', { openVoucherModal: vouchers[0].id })}
      >
        <span className="promo-bar-type">{type || 'info'}:&nbsp;</span>
        <div className="promo-bar-body ellipsis">
          {formatCapitalizePromoText(vouchers[0].reward.small_print)}
        </div>
      </div>
    </>
  ) : null;
};

export default PromoBar;
