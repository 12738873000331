import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import {
  Title,
  FieldError,
  Spacer,
  Sectiontitle,
  NormalText,
  StrongText,
  SmallText,
} from '../../components/common';
import {
  IonButton,
  IonItem,
  IonInput,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonCheckbox,
} from '@ionic/react';
import { isDefined, checkForDeliveryOption, isWebConfig } from '../../lib/utils';
import moment from '../../lib/moment';
import {
  setCommonModal,
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  setDeliveryOption,
  passedTheOrder,
} from '../../store/actions';
import { SHOW_TOAST } from '../../store/constants';
import NoData from '../../components/noData';
import { getMenusForSelectedTime, formatDataForTime } from '../clickAndCollect';
import './index.css';
import { getConfig } from '../../appConfig';

const { SelectOption } = Mobiscroll;
const restaurantSelectPlaceholder = 'Select location';

class OrderToTable extends Component {
  state = {
    selectedRestaurant: null,
    error: '',
    isLocationAllowed: false,
    tableNumber: null,
    showTableNumber: false,
  };

  componentDidMount() {
    Basket.setOrderType('table');
    const deliveryOption = checkForDeliveryOption(
      this.props.deliveryOption ? this.props.deliveryOption : Basket.getDeliveryOption(),
      '/order-to-table',
    );
    if (deliveryOption) {
      this.props.dispatch(getRestaurants());
      this.props.dispatch(setDeliveryOption(deliveryOption));
    }
    if (Basket.getMenu()) {
      this.setState({ selectedIkentooMenu: Basket.getMenu() });
    }
  }

  componentDidUpdate(prevProps) {
    checkForDeliveryOption(this.props.deliveryOption, '/order-to-table');
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
      }
    }
  }

  selectRestaurant = (selectedRestaurant) => {
    const { restaurants, profile } = this.props;
    this.setState(
      { selectedRestaurant, pickTime: moment(Date.now()).format('HH:mm'), showTableNumber: true },
      () => {
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(
          restaurants.find((restaurant) => restaurant.id === selectedRestaurant.id),
        );
        if (Basket.getRestaurant()) {
          if (getConfig().general.hasServiceCharge) {
            if (Basket.getRestaurantServiceCharge()) {
              const service_charge_original = Basket.getRestaurantServiceCharge();
              Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
            } else {
              const defaultServiceCharge = JSON.parse(getConfig().general.defaultServiceCharge);
              Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
            }
          } else {
            Basket.setServicePercentage(0);
          }
        }
        Basket.setCollectionTime(null);
        Basket.setOrderType('table');
      },
    );
  };

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (
        isDefined(store.can_table_order) &&
        isDefined(store.is_published) &&
        store.can_table_order &&
        store.is_published
      ) {
        const currentDT = moment();
        let minDT = currentDT;
        if (store && isDefined(store.order_slot_lead_time)) {
          minDT.add(store.order_slot_lead_time, 'minutes');
        }
        const timeData = formatDataForTime(store, minDT, store.id, false, false);
        store.opened = timeData.length === 1 && timeData[0].text === 'Closed' ? 'Closed' : 'Open';
        arrForSelect.push(store);
      }
    });

    return arrForSelect;
  };

  chooseMenusForLocation = () => {
    const { __, restaurants, dispatch } = this.props;
    const { selectedRestaurant, tableNumber, pickTime } = this.state;
    if (selectedRestaurant && tableNumber && pickTime) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      if (
        !isDefined(choosenRestaurant.order_to_table_menu_id) ||
        choosenRestaurant.order_to_table_menu_id === ''
      ) {
        dispatch({
          type: SHOW_TOAST,
          message: __('This service is currently unavailable!'),
          toastType: 'warning',
        });
        return;
      }
      const menuId = choosenRestaurant.order_to_table_menu_id;
      let cutoffTimeRes = moment().unix();
      Basket.setCutoffTime(cutoffTimeRes);
      Basket.setTableNumber(tableNumber);
      Basket.setMenu(menuId);
      dispatch(
        getIkentooMenusForLocation(businessLocationId, {
          pickTime,
          json_time_selector: choosenRestaurant ? choosenRestaurant.table_json_time_selector : [],
        }),
      );
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
    } else if (!selectedRestaurant) {
      this.setState({ error: __('Please select location') });
    } else {
      this.setState({ error: __('Please enter table number') });
    }
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };

  render() {
    const {
      __,
      deliveryOption,
      restaurants,
      isChooseMenuModalOpen,
      ikentooMenusForLocation,
    } = this.props;
    const {
      error,
      selectedIkentooMenu,
      pickTime,
      selectedRestaurant,
      showTableNumber,
    } = this.state;
    const stores = restaurants || [];
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant?.id) || null;
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store ? store.table_json_time_selector : [],
    );

    return (
      <Layout
        hideSecondToolbar={true}
        headerTitle={__(deliveryOption ? deliveryOption.label : '')}
        noPadding
        color="transparent"
      >
        {!showTableNumber && (
          <div className="click-collect-layout">
            <div className="click-collect-wrapper">
              <div className="click-collect-dialog-header">
                <Title>{__('Order To Table')}</Title>
                <SmallText className="heading">{__('Select a restaurant')}</SmallText>
              </div>
              <div className="click-collect-locations">
                <IonList>
                  {this.formatDataForSelect(stores).map((store, index) => (
                    <div key={index} className="click-collect-items">
                      <IonItem
                        disabled={store.opened === 'Closed'}
                        onClick={() => this.setState({ selectedRestaurant: store })}
                        lines="none"
                      >
                         <IonLabel>
                          <div className="bold-description">{__(store.name)}</div>
                          <div className="click-collect-description">{__(store.address)}</div>
                          {store.distance && (
                            <div className="bold-description click-collect-small-description">
                              {store.distance < 1
                                ? store.distance.toFixed(1)
                                : store.distance.toFixed(0)}{' '}
                              {__('miles')} - {__(store.opened)}
                            </div>
                          )}
                        </IonLabel>
                        <IonCheckbox
                          checked={selectedRestaurant && store.id === selectedRestaurant.id}
                          slot="start"
                          color="secondary"
                        />
                      </IonItem>
                    </div>
                  ))}
                </IonList>
              </div>
            </div>
            <div className="click-collect-button">
              <IonButton
                disabled={!selectedRestaurant}
                expand="block"
                color="secondary"
                onClick={() => this.selectRestaurant(selectedRestaurant)}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        )}
        {showTableNumber && (
          <div className="click-collect-layout">
            <div>
              <Loading transparent>
                <div className="click-collect-wrapper">
                <Title className="click-collect-title">
                  {deliveryOption ? `${__(deliveryOption.label)}` : ''}
                </Title>
                <NormalText className="delivery-option-description">
                  {__(
                    'Start an order to be delivered to your table. This service is available for in store use only.',
                  )}
                </NormalText>
                <Spacer size={2} />
                <div className="click-collect-dialog-content">
                  <IonList>
                      <IonItem key={selectedRestaurant.id} lines="full">
                        <div tabIndex="-1"></div>
                        <IonCheckbox color="tertiary" slot="start" checked />
                        <IonLabel className="ion-text-wrap" color="dark">
                          {selectedRestaurant.name}
                          <br />
                          {selectedRestaurant.address}
                        </IonLabel>
                      </IonItem>
                  </IonList>
                  <div>
                    <IonButton
                      expand="block"
                      fill="clear"
                      color="tertiary"
                      className="link underlined"
                      onClick={() =>
                        this.setState({
                          selectedRestaurant: null,
                          showTableNumber: false,
                          tableNumber: null,
                        })
                      }
                    >
                      {__('At a different location?')}
                    </IonButton>
                  </div>
                </div>
                </div>
                <Spacer size={2} />
                <div className="table-number">
                  <StrongText className="roboto-slab time-picker-label" htmlFor="demo-non-form">
                    {__('Table number')}
                  </StrongText>
                  <IonInput
                    className="table-number-input"
                    onIonChange={(e) => this.setState({ tableNumber: e.target.value })}
                    value={this.state.tableNumber}
                    type="text"
                    inputmode="tel"
                    placeholder="Enter your table number"
                  />
                  {error ? (
                    <IonItem>
                      <div tabIndex="-1"></div>
                      <FieldError className="field-error" value={__(error)} />
                    </IonItem>
                  ) : null}
                </div>
                <Spacer size={2} />
              </Loading>
              <div className="click-collect-button">
                <IonButton
                  disabled={this.state.tableNumber ? false : true}
                  expand="block"
                  color="secondary"
                  onClick={() => this.chooseMenusForLocation()}
                >
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          </div>
        )}
        <div className={`click-collect-dialog ${animationMenuClass}`}>
          <div className="click-collect-dialog-layout sc-ion-modal-md">
            <div className="click-collect-dialog-header">
              <h3>{__('Choose menu')}</h3>
            </div>
            <div
              className="click-collect-dialog-closer"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
            >
              <ion-icon
                name="close"
                role="img"
                className="md hydrated"
                aria-label="close"
              ></ion-icon>
            </div>
            <div className="click-collect-dialog-content">
              <IonList lines="none">
                <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                  {menus.length === 0 ? (
                    <NoData />
                  ) : (
                    menus.map((menu) => {
                      const { ikentooMenuId, menuName } = menu;
                      return (
                        <IonItem key={ikentooMenuId} lines="full">
                          <div tabIndex="-1"></div>
                          <IonLabel className="ion-text-wrap">
                            <Sectiontitle>{menuName}</Sectiontitle>
                          </IonLabel>
                          <IonRadio
                            color={isWebConfig() ? 'secondary' : 'white'}
                            slot="start"
                            value={ikentooMenuId}
                          />
                        </IonItem>
                      );
                    })
                  )}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="click-collect-dialog-action">
              {error ? (
                <IonItem>
                  <div tabIndex="-1"></div>
                  <FieldError className="field-error" value={__(error)} />
                </IonItem>
              ) : null}
              <IonButton
                disabled={pickTime && menus.length > 0 ? false : true}
                expand="block"
                color="secondary"
                className="uppercase okx-font-secondary round-button"
                onClick={() => this.continueOnMenu()}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption } = state.orders;
  const { restaurants, ikentooMenusForLocation } = state.restaurants;
  const { isChooseMenuModalOpen } = state.common;
  return {
    deliveryOption,
    restaurants: restaurants || [],
    profile: state.profile.profile,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    ikentooMenusForLocation: ikentooMenusForLocation || [],
  };
};

export default connect(stateToProps)(withTranslation(OrderToTable));
