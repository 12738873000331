import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import QRCode from 'qrcode-react';
import Basket from '../../lib/basket';
import moment from 'moment';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { getRewards, getTransactionHistory } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject } from '../../lib/utils';
import UnlockRewardItems from '../../components/unlockRewardItems';

import {
  NormalText,
  SmallText,
  StrongText,
  Subtitle,
  BigLabel,
  Title,
} from '../../components/common';
import { IonCol, IonRow } from '@ionic/react';
import { getConfig } from '../../appConfig';
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      loyaltyType: ''
    };
  }

  componentDidMount() {
    this.props.dispatch(getRewards());
    this.props.dispatch(getTransactionHistory());
    const loyaltyType = getConfig().appType.hasUnlockRewardsOnLoyalty ? 'rewards' : 'points';
    this.setState({ loyaltyType })
    if (this.props.location && this.props.location.state && this.props.location.state.tab) {
      this.setState({ page: this.props.location.state.tab });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.tab &&
      prevProps.location &&
      prevProps.location.state &&
      prevProps.location.state.tab &&
      prevProps.location.state.tab !== this.props.location.state.tab
    ) {
      this.setState({ page: this.props.location.state.tab });
    }
  }
  drawLoyaltyLabel(item) {
    let label = ''
    if (item.business_location_id == '-1') {
      label = 'Referral Bonus'

    }
    else if (item.business_location_id == '-2') {
      label = 'Sign up Bonus'

    }
    else if (item.business_location_id == '-3') {
      label = 'Refunded points'
    }
    else {
      if (item.stamp_power < 0) {
        label = 'Redeemed'
      }
      else {
        label = 'Earned'
      }
    }

    return label
  }
  drawLoyaltyContent(profile, rewards, __, qr_code) {
    const { loyaltyType } = this.state;
    if (loyaltyType == 'points') {
      return (
        <div className="loyalty-content">
          <NormalText className="bold">{__('YOUR POINTS BALANCE')}</NormalText>
          <div className="content-box small-padding loyalty-content-wrapper">
            <p className="loyalty-content-title bold">{__('Loyalty Balance')}</p>
            <p className="loyalty-content-value">{profile.available_balance} {__('PTS')}</p>
          </div>
          <div className="loyalty-content-desc">
            <div>{__('Collect points every time you scan.')}</div>
            <b>{__('Earn 5 points for every £ spent.')}</b>
          </div>
        </div>
      )
    }
    else if (loyaltyType === 'rewards') {
      return (
        <div className="loyalty-content">
          <NormalText className="bold">{__('AVAILABLE REWARDS')}</NormalText>
          <div className='loyalty-rewards-wrapper'>
            <UnlockRewardItems
              available_balance={profile.available_balance}
              rewards={rewards}
              qr_code={qr_code}
              __={__}
            />

          </div>

          {/* <div className='loyalty-rewards-wrapper'>
            {rewards.map(item => {
              return (
                <div className='loyalty-reward' style={{backgroundImage:`url(${item.reward.image})`}}>
                  {item.reward.cost>0 && <div className='loyalty-reward-points'><StrongText>{item.reward.cost} Points</StrongText></div>}
                  <div  className='loyalty-reward-name'><StrongText>{item.reward.name}</StrongText></div>
                </div>
              )
            })}
            </div> */}
        </div>
      )
    }
  }
  render() {
    const { __, rewards, profile, screenName, transactionHistory } = this.props;
    return (
      <Loading>
        <Layout
          color="transparent"
          hideSecondToolbar={true}
          headerTitle={__('Loyalty')}
          noPadding={true}
        >
          <div className='scrollable-y'>
            <div className="loyalty-header">
              <div className="loyalty-header-name">
                <Subtitle className="loyalty-name" color="white">
                  {screenName}
                </Subtitle>
              </div>
              <div className="loyalty-header-content">
                <div>
                  <div>
                    <Subtitle className="loyalty-points">{profile.available_balance.toLocaleString()} {__('pts')}</Subtitle>
                    <StrongText className="loyalty-text-10">
                      {Basket._calculatePointsAppliedPrice(profile.available_balance, false, true)}
                    </StrongText>
                  </div>
                  <div>
                    <StrongText className="loyalty-text-12">{__('Member since')}</StrongText>
                    <StrongText className="uppercase">{moment(profile.created_at).format('YY/MM')}</StrongText>      
                              </div>
                </div>
                <div className="qr-code">
                  {isDefined(profile.qr_code) ? (
                    <div className="qr-holder">
                      <QRCode value={profile.qr_code} size={100} />
                    </div>
                  ) : (
                    <div>
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.drawLoyaltyContent(profile, rewards, __, profile.qr_code)}

            <div className="loyalty-history">
              <NormalText className="bold">{__('Latest Transactions')}</NormalText>
              <div className="transactions-wrapper">
                {transactionHistory.map((el) => {
                  return (
                    <div className="content-box small-padding">
                      <IonRow>
                        <IonCol>
                          <NormalText className="border-color">{el.location_name}</NormalText>
                        </IonCol>
                        <IonCol style={{ textAlign: 'end' }}>
                          <StrongText className="border-color" color="border">
                            {el.stamp_power < 0 ? '-' : '+'} {Math.abs(el.stamp_power)} {__('pts')}
                          </StrongText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <StrongText className="bold border-color">
                            {__(`${this.drawLoyaltyLabel(el)}`)}
                          </StrongText>
                        </IonCol>
                        <IonCol style={{ textAlign: 'end' }}>
                          <NormalText className="border-color">
                            {' '}
                            {Basket.getDate(el.transaction_date).format('DD MMMM YYYY h:mm a')}
                          </NormalText>
                        </IonCol>
                      </IonRow>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { rewards } = state.restaurants;
  const { history } = state.orders;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}`;
  }
  return {
    profile,
    rewards: rewards || [],
    screenName,
    transactionHistory: history || [],
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Loyalty)));
